import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './WMUTableRow.scss';


const WMUTableRow = ({ city, wmus, sunday, overlay }) => {
    function openOverlay(wmu){
        overlay(wmu);
        const docDOM = document.querySelector(".overlay");
        docDOM.style.top = window.pageYOffset + "px";
        docDOM.style.left = window.pageXOffset + "px";

        docDOM.innerHTML=`
        <iframe
            src="https://files.ontario.ca/environment-and-energy/fish-and-wildlife/mnr_wmu${wmu.toLowerCase()}_pdf.pdf" 
            class="wmuTable__overlay"
            type="application/pdf" 
            />
        `
    }
    
    return (
        <tr className="wmuTable">
            <td className="wmuTable__data">{city}</td>
            <td className="wmuTable__data">{wmus.map((wmu) => {
                return <span key={uuidv4()} onClick={()=>{openOverlay(wmu)}} className="wmuTable__open">{wmu} </span>
            })}</td>
            <td className="wmuTable__data">{(sunday) ? "Yes" : "No"}</td>
        </tr>
    );
}

export default WMUTableRow;
