import React from 'react';
import './HunterOrange.scss';

import blind from '../../Images/HuntingPictures/HunterOrange/orangeHuntingBlind.gif';
import cap from '../../Images/HuntingPictures/HunterOrange/orangeHuntingBaseballCap.png';
import winterHat from '../../Images/HuntingPictures/HunterOrange/orangeHuntingWinterHat.jpg';
import jacket from '../../Images/HuntingPictures/HunterOrange/orangeHuntingJacket.jpg';
import vest from '../../Images/HuntingPictures/HunterOrange/orangeHuntingVest.jpg';

const HunterOrange = ({ animalOrange, notRequired }) => {
    if (notRequired) {
        return (
            <section className="orange">
                <h2 className="orange__title">Hunter Orange</h2>
                <p className="orange__info">{animalOrange}</p>
            </section>
        )
    }
    return (
        <section className="orange">
            <h2 className="orange__title">Hunter Orange</h2>
            <p className="orange__info">{animalOrange}</p>
            <p className="orange__info">
                A hunter orange garment and head cover must be worn. The hunter orange
                garment must cover a minimum of 2,580 square centimetres (400 square
                inches) above the waist and be visible from all sides. Open mesh or
                camouflage hunter orange must not be part of the 2,580 square
                centimetres (400 square inches). A hunting coat or vest generally
                meets this requirement. The hunter orange head cover may have: open
                mesh, a peak or brim colour other than hunter orange, and a crest or
                logo which does not completely cover the hunter orange on the side
                where it is affixed. The head cover must not contain camouflage
                material.
            </p>
            <p className="orange__info">
                Hunters using ground blinds should take steps to make their blind
                clearly visible to other hunters who may be in the area. Use of
                hunter orange material on the outside of the blind is an appropriate
                method to mark a ground blind.
            </p>
            <div className="orange__image-list">
                <img src={blind} alt="hunting blind" />
                <img src={cap} alt="orange cap" />
                <img src={jacket} alt="orange jacket" />
                <img src={winterHat} alt="orange jacket" />
                <img src={vest} alt="orange jacket" />
            </div>
        </section>
    );
}

export default HunterOrange;
