import React from 'react';
import AnimalCallingList from '../AnimalCallingList/AnimalCallingList';
import './AnimalCalling.scss';

const AnimalCalling = ({calling}) => {
    return (
        <section className="animal-calling">
            <h2 className="animal-calling__title">How to Call</h2>
            <div>
                <AnimalCallingList calling={calling}/>
            </div>
        </section>
    );
}

export default AnimalCalling;
