import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import './ParkPage.scss';
import MetaTags from 'react-meta-tags';

const ParkPage = (props) => {

    const [park, setPark] = useState("");
    const [showLoad, setLoad] = useState("flex");

    useEffect(() => {
        axios.get("/parks/hunting-parks/" + props.match.params.park)
            .then((response) => {
                setPark(response.data);
                setLoad("none");
            })
            .catch((_err) => {
            })
    }, [props.match.params.park])

    return (
        <div className="ParkPage">

            <MetaTags>
                <title>{park.name} Park</title>
                <meta
                    name="description"
                    content={`Learn about ${park.name} park for hunting, fishing, camping, or hicking!`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <div className="lds-grid-wrapper" style={{ display: showLoad }}><div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
            {(park) ? <section>
                <Link to="/public-hunting-spots"> {"<"} Back to Parks Page</Link>
                <h2>{park.name} Park</h2>
                <p>{park.phone}</p>
                <address>{park.address}</address>
                <p>WMU(s): {park.wmu}</p>
                {(park.notes) ? <p>Notes: {park.notes}</p> : null}
                {(park.lat) ? <p>lat: {park.lat} long: {park.long}</p> : null}
                <p>Size: {park.size}</p>
            </section> : null}
        </div>
    );
}

export default ParkPage;
