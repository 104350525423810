import React from 'react';
import './Panfish.scss';

import FishingNavigationBar from '../../../Components/FishingNavigationBar/FishingNavigationBar';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import MetaTags from 'react-meta-tags';

const Panfish = () => {
    return (
        <article className="panfish">
            <MetaTags>
                <title>Panfish Fishing</title>
                <meta
                    name="description"
                    content={`Check us out and learn about all the different panfish and how to fish them. 
                    Check out how to find them, and also what baits and tackle are great to use when targeting 
                    them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <FishingNavigationBar />
            <div className="panfish__wrapper">
                <h2>Coming Soon!</h2>
            </div>
        </article>
    );
}

export default Panfish;
