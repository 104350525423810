import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from '../../Components/StripeForm/StripeForm';
import "./DonationPage.scss";
import DonationCardAmount from '../../Components/DonationCardAmount/DonationCardAmount';
import MetaTags from 'react-meta-tags';
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

const { REACT_APP_STRIPE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

const DonationPage = ({ history }) => {

    const [error, setError] = useState()
    const [amount, setAmount] = useState();

    return (
        <section className="DonationPage">

            <MetaTags>
                <title>Donations</title>
                <meta
                    name="description"
                    content={`Your donations help CAWilderness in countless ways.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <div>
                <h2>Dontations</h2>
                <p>Your Donations go towards research in effort to make hunting and fishing more accessible and easy for all. Donations also go towards keeping our databases and servers up and running.</p>
                <p>Tax receipts are not issued from CA Wilderness.</p>
                <section>
                    <h3>Pick an amount to Donate</h3>
                    <div className="DonationPage__card-list">
                        <DonationCardAmount amount="1" onAmountChange={setAmount} />
                        <DonationCardAmount amount="5" onAmountChange={setAmount} />
                        <DonationCardAmount amount="10" onAmountChange={setAmount} />
                        <DonationCardAmount amount="25" onAmountChange={setAmount} />
                        <DonationCardAmount amount="50" onAmountChange={setAmount} />
                        <DonationCardAmount amount="100" onAmountChange={setAmount} />
                    </div>
                </section>
            </div>
            <div>
                <h4>{error}</h4>
                <Elements stripe={stripePromise}>
                    <StripeForm setError={setError} amountCent={(amount * 100)} history={history} />
                </Elements>
            </div>

        </section>
    );
}

export default DonationPage;
