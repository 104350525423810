import React from 'react';
import FishFind from '../../../Components/FishFind/FishFind';
import FishIdentification from '../../../Components/FishIdentification/FishIdentification';
import FishingNavigationBar from '../../../Components/FishingNavigationBar/FishingNavigationBar';
import FishIntro from '../../../Components/FishIntro/FishIntro';
import FishTackle from '../../../Components/FishTackle/FishTackle';
import './Walleye.scss';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const Walleye = () => {

    const fish = {
        intro: {
            title: `Walleye Fishing`,
            info: `Walleye are a challenge to target, but they are definetly worth the reward 
            because they are one of the best tasting fish!`
        },
        indentifying: [
            {
                title: `Walleye`,
                info: `Walleye can grown between 10 to 33 inches long. They have a 
                a white tip on the lower part of the tail fin. Walleye also usually 
                have small, sharp teeth.`
            }
        ],
        tackle: {
            info: `There are many different types of tackle you can use for catching walleye. 
            Each one is used in differen't times of the year, weather and enviernments.`,
            tackleList: ['Jigs', 'Spinner Baits', 'Crank Baits', 'Plastics', 'Minnows', 'Leeches']
        },
        findFish: {
            info: [
                {
                    title: "Walleye",
                    info: `Walleye can be found in most of Ontario's bodies of water. In the spring, walleye are 
                    usually found in more shallow, warm water. In the summer walleye can be found in the deeper 
                    parts of a lake. In the fall, walleye can be found in a mix of shallow to slightly deep water.`
                }
            ]
        }
    };

    return (
        <article className="walleye">

            <MetaTags>
                <title>walleye Fishing</title>
                <meta
                    name="description"
                    content={`Check us out and learn about walleye and how to fish them. 
                    Check out how to find walleye, and also what baits and tackle are great to 
                    use when targeting them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <FishingNavigationBar />
            <div className="walleye__wrapper">

                <FishIntro title={fish.intro.title} info={fish.intro.info} />

                <FishIdentification indentifying={fish.indentifying} />

                <FishTackle info={fish.tackle.info} tackleList={fish.tackle.tackleList} />

                <FishFind findFish={fish.findFish} />

            </div>
        </article>
    );
}

export default Walleye;
