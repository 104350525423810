import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';
import AniamlIdentification from '../../../Components/AniamlIdentification.js/AniamlIdentification';
import AnimalGear from '../../../Components/AnimalGear/AnimalGear';
import AnimalHeader from '../../../Components/AnimalHeader/AnimalHeader';
import AnimalSenses from '../../../Components/AnimalSenses/AnimalSenses';
import HuntingMethods from '../../../Components/HuntingMethods/HuntingMethods';

import './Bear.scss';

import male from '../../../Images/HuntingPictures/Bear/bearBoar.jpg';
import female from '../../../Images/HuntingPictures/Bear/bearSow.jpg';
import AnimalBehaviour from '../../../Components/AnimalBehaviour/AnimalBehaviour';

import fairEye from '../../../Images/HuntingPictures/senses/fair/sensesEyeSightFair.png';
import goodSmell from '../../../Images/HuntingPictures/senses/good/sensesSmellGood.png';
import goodHearing from '../../../Images/HuntingPictures/senses/good/sensesHearingGood.png';

import remington783 from '../../../Images/HuntingPictures/Gear/Remington783Bolt.jpg';
import rifleBullets from '../../../Images/HuntingPictures/Gear/win243G95.png';
import remington870 from '../../../Images/HuntingPictures/Gear/Remington87012G.jpg';
import shotgunShells from '../../../Images/HuntingPictures/Gear/00BuckShot.jpg';
import crossbow from '../../../Images/HuntingPictures/Gear/AXE360Crossbow.jpg';
import broadhead from '../../../Images/HuntingPictures/Gear/crossbowBroadHeads.jpg';
import AniamlActivity from '../../../Components/AniamlActivity/AniamlActivity';

import bearDroppings from '../../../Images/HuntingPictures/Bear/bearDroppings.jpg';
import bearGameCamera from '../../../Images/HuntingPictures/Bear/TrailCamofBears.jpg';
import bearDamagedTrees from '../../../Images/HuntingPictures/Bear/bearDamagedTrees.jpg';
import bearTracks from '../../../Images/HuntingPictures/Bear/bearTracks.jpg';
import HunterOrange from '../../../Components/HunterOrange/HunterOrange';

import treeStand from '../../../Images/HuntingPictures/methods/treeStand.jpg';
import bearFeeder from '../../../Images/HuntingPictures/methods/bearBaitBarrel.jpg';

import background from '../../../Images/GeneralWebsiteArt/hunting_page_background.jpg';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const Bear = () => {

    const bearObj = {
        header: {
            title: "Black Bear",
            info: `Black Bear hunting in Ontario is very popular 
            because the forests here are the ideal habitat for 
            them. The Black Bear population is the healthiest 
            in the far Northern parts of Ontario where there 
            are thick forests and human activity is sparse.`
        },
        identification: [
            {
                sex: "Boar",
                info: `A Boar will generally be more wider 
                in the front than the back and have a thick 
                neck with their ears on the side and closer 
                to the head. Boars are also much larger than 
                sows, reaching around 250-600lbs (114-272kg).`,
                image: male
            },
            {
                sex: "Sow",
                info: `A Sow is generally smaller and have an even 
                distribution of weight throughout their body. Larger 
                roundish ears on the top of their heads and can 
                sometimes be seen with their young. Sows are smaller 
                than their counterpart reaching around 100-400lbs 
                (45.4-182kg). Black Bears will typically avoid humans, 
                but a Sow with cubs will usually be aggressive if you 
                encounter them.`,
                image: female
            }
        ],
        behaviour: [
            {
                title: "",
                info: `Bear feed most heavily during cool mornings 
                and evening hours. They remain in shady areas on 
                hot days because their dark fur absorbs too much 
                heat from the sun. Windy or rainy weather will 
                reduce their activity.`
            },
            {
                title: "",
                info: `With the exception of the June breeding 
                season, males, or boars, lead solitary lives. 
                Females, sows, stay with their young for 
                about 1⅕ years.`,
            },
            {
                title: "",
                info: `In the North, bears begin to hibernate as 
                early as mid-September. They stay in their dens 
                until April but will roam around if there is a 
                warm day. In warmer climates, bears may not 
                hibernate. If they do, it will begin later on and 
                will not last long.`,
            },
            {
                title: "",
                info: `With the exception of the June breeding 
                season, males, or boars, lead solitary lives. 
                Females, sows, stay with their young for 
                about 1⅕ years.`,
            },
            {
                title: "",
                info: `Bears have attacked humans, but they usually 
                make every effort to avoid contact with people. A 
                sow(female bear) with cubs, will be aggressive if 
                you encounter them.`
            }
        ],
        senses: [
            {
                title: "Vision",
                info: `Black Bears are believed to see in color and 
                have great eyesight up close, which can be comparable 
                to most humans. Although, their eyesight at distances 
                further than 30 yards starts to lack and are unable to 
                see details of large objects.`,
                image: fairEye
            },
            {
                title: "Hearing",
                info: `Black Bears have acute hearing that is about twice as 
                sensitive as humans and over a large frequency range as 
                well. Bears often hear humans well before we even spot 
                them. Due to their heightened sense of hearing, Black 
                Bears will often move away before they are noticed.`,
                image: goodHearing
            },
            {
                title: "Smell",
                info: `A Black Bear's strongest sense, is their sense of 
                smell. They can pick up a scent from over a mile away! 
                That is just over seven times better than a bloodhound. 
                Bears are considered to have the best sense of smell of 
                any land mammal. Their rumored poor vision may be due 
                to their reliance on their sense of smell.`,
                image: goodSmell
            }
        ],
        gear: [
            {
                title: "Rifle (Center Fire Only)",
                info: `A good hunting rile for begginers is the Remington 783 Bolt Action 
                Rifle. You can get this rifle in a .243 Winchester Cartridge which is a 
                great light caliber, for beggining bear hunters. You should use at least 
                a 90-100 grain bullets when hunting bear or similar game.`,
                image1: remington783,
                image2: rifleBullets
            },
            {
                title: "Shotgun (No smaller than 20 gauge)",
                info: `A 12 gauge shotgun is recommended, along with 3 or 2 3/4 inch, 
            00 Buckshots, and improved cylinder choke tube. 00 Buckshots (9-15 pellets) 
            are recommended because you will have a better chance of putting down the Bear. 
            You can also use slugs, but the slug projectile is usually harder to hit 
            Bear with. If your shotgun has a scope, then you should consider slugs. 
            Shotgun with rifled barrels should only be shooting Sabot Slugs, slugs without 
            lines on the side. If you have a smoothbore barrel, then your shotgun should be 
            shooting Rifled Slugs. A good starter shotgun that's great for many different 
            types of hunting, the Remingtion 870 is a good, reliable, cheap shotgun that will
            get the job done.`,
                image1: remington870,
                image2: shotgunShells
            },
            {
                title: "Bows & Crossbows",
                info: `Usually bows and corssbow are in sessean all year around, depending on 
                the WMU you hunt in. This being said, if you plan on doing a lot of hunting, 
                a bow or corss bow is definaitly worth your money. One brand that makes out standing
                corss bows is Excalibur. There is a cross bow they make called the AXE 340, and this 
                is an outstanding product. It is very light and has crazy firing power. Highly recommend 
                for all levels of deer hunters. While shooting a cross bow, you need to have at broadhead 
                at the end of your bolt. The broadhead must have 2 sharp cutting edges and must be 22mm or larger.`,
                image1: crossbow,
                image2: broadhead
            },
        ],
        activity: [
            {
                title: "Droppings",
                info: "Droppings show you what a bear has been eating. They often contain berries, grass, hair, and wood eaten along with insects",
                image: bearDroppings
            },
            {
                title: "Tracks",
                info: "Tracks have distinct claw marks. They measure 3 1/5 to 5 inches wide. The front foot is 4 to 5 inches long. The hind foot is 5 to 6 inches.",
                image: bearTracks
            },
            {
                title: "Damaged Trees",
                info: "Damaged trees or bushes are common feeding signs. A bear rips down a branch with its claws, then strips off the fruit or berries. Claw marks on trees usually indicated they are climbing in the area.",
                image: bearDamagedTrees
            },
            {
                title: "Game Cameras",
                info: "A trail cam will also give you a pretty good idea of what Bears are in the area. They helps you figure out what habbits the Bears have. A trail cam let's you create a census of Bears in your area. Moultrire® Cameras are recommended. They are reliable and provide great results",
                image: bearGameCamera
            }
        ],
        methods: [
            {
                title: "Tree Stands",
                info: `Hunting out of a tree stand is usually the way 
                to go. Bear have a great sense of smell, being 15 feet 
                in the air will help with scent control. A good tree 
                stand is usually 15 to 25 feet high, but, the higher 
                above the bear's sightline, the better it is for the
                hunter.`,
                note: `Make sure to always wear a safety harness. Doesn't matter 
                if your hunting or setting up the stand, it is always possible to 
                fall out of the tree stand`,
                image: treeStand
            }, {
                title: "Bait",
                info: `Use a big barrel with a hole cut in the middle about 
                1 1/5 feet in diameter. This barrel is going to hold about 
                80% of your bait, with the rest throw around the barrel. 
                Mainly corn or bread makes a great bait, along with any type 
                of syrup or grease. The syrup or grease is mainly used to 
                attract the bear from the strong oddor. You should dump most 
                of the oil or grease on the floor, around the barrel or bait. 
                This will cause the bear to step in it, and make scent trails 
                throughout the forest when they leave. Make sure to secure the 
                barrel to a strong standing tree. You don't want it falling over.`,
                note: `To bait legally in Ontario, you must be at least 500 meters 
                from any personal dwellings or public buildings and also 200 
                meters from any paths used by vehicles. It is also illegal to hunt 
                black bear 400m from any waste disposal site`,
                image: bearFeeder
            }
        ],
        orange: {
            info: `All licensed Bear hunters are required to wear hunter orange except when in a tree stand.`
        }
    }

    const { pathname } = useLocation();
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <article className="bear">
            <MetaTags>
                <title>Black Bear</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Black Bears and how to hunt them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <div className="bear__first-wrapper">
                <img className="bear__back" src={background} alt="background" />
            </div>
            <AnimalNavigationBar />
            <div className="bear__wrapper">
                <AnimalHeader header={bearObj.header} />
                <AniamlIdentification identification={bearObj.identification} />
                <AnimalBehaviour behaviour={bearObj.behaviour} />
                <AnimalSenses senses={bearObj.senses} />
                <AnimalGear gear={bearObj.gear} />
                <AniamlActivity activity={bearObj.activity} />
                <HuntingMethods methods={bearObj.methods} />
                <HunterOrange animalOrange={bearObj.orange.info} />
            </div>
        </article>
    );
}

export default Bear;