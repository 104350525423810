import React from 'react';
import { Link } from "react-router-dom";

import './NotFoundPage.scss'

const NotFoundPage = () => {
    return <div className="NotFoundPage">
        <h2 className="NotFoundPage__text">Sorry, can't find this page :(</h2>
        <Link to='/' className="NotFoundPage__btn">Go Home</Link>
    </div>;
}

export default NotFoundPage;