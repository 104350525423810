import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";

import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from "react-meta-tags";

const Home = () => {
    return (
        <section className="Home">
            <MetaTags>
                <title>CA Wilderness</title>
                <meta
                    name="description"
                    content={`Everything you need in one place for learning about fishing and hunting in Ontairo.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <link rel="apple-touch-icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <h1 className="Home__title">Welcome To Ontario Wilderness!</h1>
            <div className="Home__content-wrapper">
                <Link className="Home__link-wrapper" to="/hunting" tabIndex="0">
                    <div className="Home__link">
                        <h2>Hunting</h2>
                        <div className="Home__back Home__back--hunt">
                            <div className="Home__back-wrapper"></div>
                        </div>
                    </div>
                </Link>
                <Link className="Home__link-wrapper" to="/fishing" tabIndex="0">
                    <div className="Home__link">
                        <h2>Fishing</h2>
                        <div className="Home__back Home__back--fish">
                            <div className="Home__back-wrapper"></div>
                        </div>
                    </div>
                </Link>
                <Link className="Home__link-wrapper" to="/find-WMU" tabIndex="0">
                    <div className="Home__link">
                        <h2>Find Your WMU</h2>
                        <div className="Home__back Home__back--WMU">
                            <div className="Home__back-wrapper"></div>
                        </div>
                    </div>
                </Link>
                <Link className="Home__link-wrapper" to="/public-hunting-spots" tabIndex="0">
                    <div className="Home__link">
                        <h2>Public Hunting Land</h2>
                        <div className="Home__back Home__back--public">
                            <div className="Home__back-wrapper"></div>
                        </div>
                    </div>
                </Link>
            </div>
        </section>
    );
};

export default Home;
