import React from 'react';
import AniamlIdentificationCards from '../AniamlIdentificationCards/AniamlIdentificationCards';
import './AniamlIdentification.scss';

const AniamlIdentification = ({ identification, newTitle }) => {
    return (
        <section className="aniaml-identification">
            <h2 className="aniaml-identification__title">{(newTitle) ? newTitle : "Identifying the Animal"}</h2>
            <div className="aniaml-identification__wrapper">
                {identification.map((data) => {
                    return (<AniamlIdentificationCards key={data.sex} title={data.sex} info={data.info} image={data.image} />);
                })}
            </div>
        </section>
    );
}

export default AniamlIdentification;
