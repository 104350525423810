import React from 'react';
import FishFind from '../../../Components/FishFind/FishFind';
import FishIdentification from '../../../Components/FishIdentification/FishIdentification';
import FishingNavigationBar from '../../../Components/FishingNavigationBar/FishingNavigationBar';
import FishIntro from '../../../Components/FishIntro/FishIntro';
import FishTackle from '../../../Components/FishTackle/FishTackle';
import './Trout.scss';

import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import MetaTags from 'react-meta-tags';

const Trout = () => {

    const fish = {
        intro: {
            title: `Trout Fishing`,
            info: `Trout are some of the best tasting fish. They are also not the easiest
            to target. You need to make sure you are using the correct baits and tackle.`
        },
        indentifying: [
            {
                title: `Lake Trout`,
                info: `Lake trout can grow between 12 to 31 inches long. On the lower fins there
                is a white leading edge. There is a light wormlike pattern on the top, darker 
                part of the fish.`
            },
            {
                title: `Brook Trout`,
                info: `Brook trout can grow between 6 to 16 inches long. The top of the fish
                is ususally a darker color with a tiger patterned of a lighter color on top.`
            },
            {
                title: `Brown Trout`,
                info: `Brown trout can grow between 8 to 16 inches long. They usually have 
                large black, blue and red spots all over their body. They have orange on the 
                top fin right in front of the tail (adipose fin)`
            },
            {
                title: `Rainbow Trout`,
                info: `Rainbow trout can grow between 6 to 16 inches long. They have a pink
                stripe that runs from the head to the tail. They also have many small black 
                spots all over their body.`
            }
        ],
        tackle: {
            info: `There are many different types of tackle you can use for catching Trout. 
            Each one is used in differen't times of the year, weather and enviernments.`,
            tackleList: ['Spoons', 'Worms', 'Flies', 'Spinners', 'Jigs', 'Crank Baits']
        },
        findFish: {
            info: [
                {
                    title: `Lake Trout`,
                    info: `Lake trout can be found in cold, deep water. They are in many lakes throught Ontario.`
                },
                {
                    title: `Brook Trout`,
                    info: `Brook trout can found in cold, clear streams. They are also in many of the small lakes and in Lake Superior.`
                },
                {
                    title: `Brown Trout`,
                    info: `They can be found in many different lakes in ontario.`
                },
                {
                    title: `Rainbow Trout`,
                    info: `They can be found in many different lakes in ontario.`
                }
            ]
        }
    };

    return (
        <article className="trout">

            <MetaTags>
                <title>Trout Fishing</title>
                <meta
                    name="description"
                    content={`Check us out and learn about trout and how to fish them. 
                    Check out how to find trout, and also what baits and tackle are great to 
                    use when targeting them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <FishingNavigationBar />
            <div className="walleye__wrapper">

                <FishIntro title={fish.intro.title} info={fish.intro.info} />

                <FishIdentification indentifying={fish.indentifying} />

                <FishTackle info={fish.tackle.info} tackleList={fish.tackle.tackleList} />

                <FishFind findFish={fish.findFish} />

            </div>
        </article>
    );
}

export default Trout;
