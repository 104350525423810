import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import H3NText from '../H3NText/H3NText';

const FishFind = ({findFish}) => {
    return (
        <section>
            <h2>How To Find The Fish</h2>
            {findFish.info.map((index) => {
                return <H3NText key={uuidv4()} title={index.title} info={index.info} />
            })}
        </section>
    );
}

export default FishFind;
