import React from 'react';
import './AniamlIdentificationCards.scss';

const AniamlIdentificationCards = ({ title, info, image }) => {
    return (
        <section className="aniaml-identification-cards">
            <h3 className="aniaml-identification-cards__title">{title}</h3>
            <div className="aniaml-identification-cards__wrapper">
                <p className="aniaml-identification-cards__info">{info}</p>
                <img className="aniaml-identification-cards__image" src={image} alt={title} />
            </div>
        </section>
    );
}

export default AniamlIdentificationCards;
