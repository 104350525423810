import React from 'react';
import AnimalCallingCards from '../AnimalCallingCards/AnimalCallingCards';
import './AnimalCallingList.scss';

const AnimalCallingList = ({calling}) => {
    return (
        <div className="animal-calling-list">
            <p className="animal-calling-list__info">{calling.info}</p>
            {calling.calls.map((call)=>{
                    return (<AnimalCallingCards key={call.title} call={call}/>)
                })}
        </div>
    );
}

export default AnimalCallingList;
