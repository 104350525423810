import React from 'react';
import { Link } from "react-router-dom";
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import MetaTags from 'react-meta-tags';
import './McRaePoint.scss';

const Mcraepoint = () => {
    return (
        <div className="McraePoint">
            <MetaTags>
                <title>McRae Point Park</title>
                <meta
                    name="description"
                    content={`Learn about McRae Point Park for hunting, fishing, camping, or hicking!`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <Link to="/public-hunting-spots"> {"<"} Back to Parks Page</Link>
            <article>
                <section>
                    <h2>McRae Point Provincial Park</h2>
                </section>
                <section>
                    <h3>What Can You Do Here?</h3>
                    <p>Only duck hunting is permitted here. No other hunting is allowed (big game & small game)</p>
                </section>
                <section>
                    <h3>Fees</h3>
                    <p>There are no fees at this park for hunting.</p>
                </section>
                <section>
                    <h3>Location</h3>
                    <p>This park can be found in Orillia, Ontario. The park is on the North-East side of Lake Simcoe</p>
                    <address>4366 McRae Park Road Ramara Township L3V 6H7</address>
                    <address>lat: 44.569000 long: -79.329000</address>
                </section>
                <section>
                    <h3>Rules</h3>
                    <p>Duck hunting is only permitted the Tuesday after Thanksgiving up until the end of the session.</p>
                    <p>There are only two hunters allowed per blind</p>
                    <p>There are only two hunting spots here. The left & right points.</p>
                    <p>When arriving to the park, park on the left side of the gate if you plan on hunting on the left point. Same goes with the right point.</p>
                </section>
                <section>
                    <h3>Contact The Park</h3>
                    <p>You can reach the park at: 705-325-7290</p>
                </section>
            </article>
        </div>
    );
}

export default Mcraepoint;
