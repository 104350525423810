import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import "./PublicHunting.scss";

import MetaTags from "react-meta-tags";

const PublicHunting = () => {
    const [parks, setParks] = useState([]);
    const [publicFilter, setPublicFilter] = useState("");

    useEffect(() => {
        axios.get("/parks/hunting-parks").then((response) => {
            setParks(response.data);
        });
    }, []);

    return (
        <article className="public-hunting">
            <MetaTags>
                <title>Ontario Public Hunting Land</title>
                <meta
                    name="description"
                    content={`Checkout all the public parks in Ontario for some new hunting spots!`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <section>
                <h2>Where Can I Hunt?</h2>
                <p>
                    One of the hardest part of hunting is figuring out where to hunt. Here
                    you can find public hunting land, as well as tips on how to find
                    private land.
                </p>
            </section>
            <section>
                <h3>Ontario Provincal Parks</h3>
                <p>
                    Here are provincal parks that allows hunting. Click one for more info.
                </p>
                <input
                    type="text"
                    className="public-hunting__search"
                    value={publicFilter}
                    onChange={(e) => {
                        setPublicFilter(e.target.value);
                    }}
                />
            </section>
            <ul className="public-hunting__list">
                {parks
                    .filter((park) => {
                        return (
                            park.name.toLowerCase().includes(publicFilter ? publicFilter.toLowerCase() : null) ||
                            !publicFilter
                        );
                    })
                    .map((park) => {
                        return (
                            <li key={uuidv4()} className="public-hunting__list-item">
                                <Link to={`/public-hunting-spots/${park.name}`}>
                                    <h3 className="public-hunting__list-item-title">
                                        {park.name} Park
                                    </h3>
                                    <address>{park.address}</address>
                                    <p className="public-hunting__list-item-p">
                                        Size: {park.size}
                                    </p>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </article>
    );
};

export default PublicHunting;
