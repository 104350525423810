import React from 'react';
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

import './Fishing.scss';

//https://files.ontario.ca/environment-and-energy/fishing/198234.pdf

import bassIcon from '../../Images/FishingPictures/index/bass-icon.jpg';
import panfishIcon from '../../Images/FishingPictures/index/panfish-icon.jpg';
import pikeIcon from '../../Images/FishingPictures/index/pike-icon.jpg';
import troutIcon from '../../Images/FishingPictures/index/trout-icon.jpg';
import walleyeIcon from '../../Images/FishingPictures/index/walleye-icon.jpg';
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

import AnimalPresentation from "../../Components/AnimalPresentation/AnimalPresentation";

const Fishing = () => {
    const animals = [
        {
            name: "Bass",
            img: bassIcon,
            description: "Large and Small Mouth Bass are one of the most fun fish to target."
        },
        {
            name: "Pan Fish",
            img: panfishIcon,
            description: "Perch, Crappie, Bluegill - All great panfish and easiest to catch."
        },
        {
            name: "Northern Pike",
            img: pikeIcon,
            description: "Northern Pike are hands down the most aggressive fish. They will swallow almost anything!"
        },
        {
            name: "Trout",
            img: troutIcon,
            description: "There Rainbow, Brown, Lake, and Brook Trout. Each a challenge to catch!"
        },
        {
            name: "Walleye",
            img: walleyeIcon,
            description: "Walleye are very distinct between other fish. Many cool ways to fish them."
        }
    ];
    return (
        <section className="fishing">

            <MetaTags>
                <title>Fishing Index</title>
                <meta
                    name="description"
                    content={`Check us out and learn about many differen't fish you can target in Ontario!`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <h1>Fishing In Ontario</h1>
            <div>
                {animals.map((data) => {
                    return <Link to={`/fishing/${data.name.replace(" ", "-")}`} key={uuid()}><AnimalPresentation animal={data} /> </Link>
                })}
            </div>
        </section>
    );
}

export default Fishing;
