import React from 'react';
import './DonationCardAmount.scss';

const DonationCardAmount = ({amount, message, onAmountChange}) => {
    
    return (
        <label className="DonationCard" onClick={()=>{onAmountChange(amount)}}>
            <input type="radio" name="donation" className="DonationCard__input" />
            <div className="DonationCard__back"></div>
            <h2 className="DonationCard__title">Donate ${amount}.00</h2>
            <p className="DonationCard__message">{message}</p>
        </label>
    );
}

export default DonationCardAmount;
