import React from 'react';
import './AnimalGearCard.scss';

const AnimalGearCard = ({ title, info, image1, image2 }) => {
    return (
        <div className="aniaml-gear-card">
            <h3 className="aniaml-gear-card__title">{title}</h3>
            <p className="aniaml-gear-card__info">{info}</p>
            <div className="aniaml-gear-card__wrapper">
                <img className="aniaml-gear-card__img" src={image1} alt={title} />
                {(image2) ? <img className="aniaml-gear-card__img" src={image2} alt={title} /> : null}
            </div>
        </div>
    );
}

export default AnimalGearCard;
