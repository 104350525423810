import React from 'react';
import './AnimalSenses.scss';
import { v4 as uuidv4 } from 'uuid';
import senseMeter from '../../Images/HuntingPictures/General/senseMeter.png';
import AnimalSensesCard from '../AnimalSensesCard/AnimalSensesCard';

const AnimalSenses = ({senses}) => {
    return (
        <section className="animal-senses">
            <h2 className="animal-senses__title">Senses</h2>
            <div className="animal-senses__wrapper">
                <img className="animal-senses__meter" src={senseMeter} alt="sense meter" />
                {senses.map((data)=>{
                    return (<AnimalSensesCard title={data.title} info={data.info} image={data.image} key={uuidv4()}/>)
                })}
            </div>
        </section>
    );
}

export default AnimalSenses;
