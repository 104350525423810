import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AnimalHeader from '../../../Components/AnimalHeader/AnimalHeader';
import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';
import AniamlIdentification from '../../../Components/AniamlIdentification.js/AniamlIdentification';
import AnimalGear from '../../../Components/AnimalGear/AnimalGear';
import HuntingMethods from '../../../Components/HuntingMethods/HuntingMethods';

import './WaterFowl.scss'

//Duck Pictures

//puddles
import mallard from '../../../Images/HuntingPictures/waterfowl/mallard.jpg';
import blackDuck from '../../../Images/HuntingPictures/waterfowl/blackDuck.jpg';
import woodDuck from '../../../Images/HuntingPictures/waterfowl/woodDuck.jpg';
import pintail from '../../../Images/HuntingPictures/waterfowl/pintail.jpg';
import greenWingedTeal from '../../../Images/HuntingPictures/waterfowl/green-wingedTeal.jpg';
import blueWingedTeal from '../../../Images/HuntingPictures/waterfowl/blue-wingedTeal.jpg';
import shoveler from '../../../Images/HuntingPictures/waterfowl/shoveler.jpg';
import gadwell from '../../../Images/HuntingPictures/waterfowl/gadwell.jpg';
import americanWidgeon from '../../../Images/HuntingPictures/waterfowl/americanWidgeon.jpg';

//diving
import buffleHead from '../../../Images/HuntingPictures/waterfowl/bufflehead.jpg';
import redHead from '../../../Images/HuntingPictures/waterfowl/redhead.jpg';
import canvasback from '../../../Images/HuntingPictures/waterfowl/canvasback.jpg';
import scaup from '../../../Images/HuntingPictures/waterfowl/scaup.jpg';
import ringNeckedDuck from '../../../Images/HuntingPictures/waterfowl/ring-neckedDuck.jpg';
import longTailedDuck from '../../../Images/HuntingPictures/waterfowl/long-tailedDuck.jpg';
import commonMerganser from '../../../Images/HuntingPictures/waterfowl/commonMerganser.jpg';
import hoodedMerganser from '../../../Images/HuntingPictures/waterfowl/hoodedMerganser.jpg';
import commonGoldenEye from '../../../Images/HuntingPictures/waterfowl/commonGoldeneye.jpg';

//geese
import canadaGeese from '../../../Images/HuntingPictures/waterfowl/canadaGeese.jpg';
import snowGoose from '../../../Images/HuntingPictures/waterfowl/snowGoose.jpg';

//birds
import americanWoodcock from '../../../Images/HuntingPictures/waterfowl/americanWoodcock.jpg';
import commonSnipe from '../../../Images/HuntingPictures/waterfowl/commonSnipe.jpg';
import mourningDove from '../../../Images/HuntingPictures/waterfowl/mourningDove.jpg';

//gear pictures
import waterfowlLoad from '../../../Images/HuntingPictures/Gear/waterfowl-load.jpg';
import SuperVinci from '../../../Images/HuntingPictures/Gear/SuperVinci.jpg';
import hipWaders from '../../../Images/HuntingPictures/Gear/hip-waders.jpg';
import lamp from '../../../Images/HuntingPictures/Gear/head-lamp.jpg';
import lanyard from '../../../Images/HuntingPictures/Gear/lanyard.jpg';
import call from '../../../Images/HuntingPictures/Gear/double_nasty.jpg';
import gloves from '../../../Images/HuntingPictures/Gear/gloves.jpg';
import decoys from '../../../Images/HuntingPictures/Gear/duck_decoys.jpg';
import mojo from '../../../Images/HuntingPictures/Gear/mojo.jpg';
import weightLines from '../../../Images/HuntingPictures/Gear/decoy_ancor.jpg';
import decoyBag from '../../../Images/HuntingPictures/Gear/decoy_bag.jpg';
import blindBagOpen from '../../../Images/HuntingPictures/Gear/blindBagOpen.png';
import blindBagClosed from '../../../Images/HuntingPictures/Gear/blindBagClosed.png';

import huntingInWater from '../../../Images/HuntingPictures/methods/duck_spread.png';

import background from '../../../Images/GeneralWebsiteArt/hunting_page_background.jpg';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const WaterFowl = () => {
    const mainObj = {
        header: {
            title: "Waterfowl",
            info: `There's nothing like a good weekend duck hunt with your buddies. One of the most enjoyable experinces even if 
            you get sunked! To hunt small game in ontario you must have a federal Migratory Game Bird Hunting Permit and a Wildlife
            Habitat Conservation Stamp, as well as an Outdoors Card and Small Game Licence`
        },
        puddleDucks: [
            {
                sex: "Mallard",
                info: `A Mallard drake can be identified by the iridescent blue wing 
                speculum bordered by two white horizontal bars while the hens are covered 
                in brown feathers with a dark orange beak`,
                image: mallard
            },
            {
                sex: "Black Duck",
                info: `The Black Duck has a dark, sooty appearance with a lighter head. 
                Its feet are an orange colour. the black duck has a purplish-blue speculum. 
                From below, the underside of the wing is white, in contrast to the very 
                dark body plumage. it is a close relative of the mallard and often mates 
                with mallards producing "hybrids". They are similar in size and shape and 
                often occupy the same areas.`,
                image: blackDuck
            }
            ,
            {
                sex: "Wood Duck",
                info: `The male Wood Duck has dark cinnamon-coloured iridescent plumage 
                on the chest, with white flecking. The sides are tan, and the belly is 
                white. the male has distinct white strips on its head crest. The eyes 
                are red, the bill is short and multicoloured, and the feet are a dull 
                gold colour. The female is a drab gray brown with a white teardrop around 
                the eye. the speculum is blue with a narrow white band on the end of 
                the feathers.`,
                image: woodDuck
            }
            ,
            {
                sex: "Pintail",
                info: `The male has a brown back, white belly, distinctive pointed tail and 
                brown head. in breeding plumage, a line of white feathers goes up the neck 
                to the back of the head. Females are a drab gray brown on the back and head, 
                with a whitish gray belly.`,
                image: pintail
            },
            {
                sex: "Green-Winged Teal",
                info: `The Green winged teal is the smallest duck in Ontario. Males have a 
                reddish head with a green mask that runs from the eyes to the back of the 
                neck. the body is gray on the back and white on the belly, with a yellowish 
                chest spotted with black. Females are a drab gray brown with a white belly.`,
                image: greenWingedTeal
            },
            {
                sex: "Blue-Winged Teal",
                info: `The male blue-winged teal has a gray-brown body and head with a distinctive 
                white crescent between the eye and the bill. The female has a similar coloured body 
                but not a white face crescent. The speculum is light to dark green. The above area 
                is blue with the males having a thicker band of white in the middle.`,
                image: blueWingedTeal
            },
            {
                sex: "Shoveler",
                info: `The large spoon-shaped bill of the Shoveler or “spoonbill” is a distinctive 
                feature. The male has a greenish head, white neck and chest, and a mahogany brown 
                body. The female is a drab brownish colour. The speculum looks very similar to the 
                green winged teal but is distinguished apart with its spoon like bill.`,
                image: shoveler
            },
            {
                sex: "Gadwell",
                info: `The male Gadwell has a grayish-brown head, a gray body and a whitish belly. 
                The female has similar colours. The speculum is gray with a few white feathers next 
                to the body. The above feathers are rustic in look and gray on the female.`,
                image: gadwell
            },
            {
                sex: "American Widgeon",
                info: `The widgeon is also called the “Baldpate” because of the white crown on the 
                head of the breeding male. The male has a light brown head flecked with black feathers. 
                It has a brownish-green eyepatch that curves to the back of the neck. The top of the 
                head is white. The body is dark on the back with a reddish-brown chest and sides, and 
                a white belly. The speculum is greenish-black colour. The male has blue feathers on the 
                top and a lot whiter then the female.`,
                image: americanWidgeon
            }
        ],
        divingDucks: [
            {
                sex: "Bufflehead",
                info: `The male bufflehead has a large, fan-shaped white patch behind the eye, 
                extending to the side and back of a black head. The female has a similar but 
                much smaller patch. The speculum is white the male has white feathers above 
                the white speculum patch, while the female has dark feathers.`,
                image: buffleHead
            },
            {
                sex: "Redhead",
                info: `The redhead male has a reddish head, black neck and chest, and a gray body. 
                The female is a brownish colour with a grayish white belly. The speculum is gray 
                with a darker gray lower bar.`,
                image: redHead
            },
            {
                sex: "Canvasback",
                info: `A large head with an obvious sloped profile. The male has a dark red head, 
                and a black neck and chest, with a light gray body. The female has a light brown 
                head, neck and chest, with a whitish gray body. The speculum is whitish gray in 
                colour. The sloped bill is the main identifying feature.`,
                image: canvasback
            },
            {
                sex: "Greater and Lesser Scaup",
                info: `These two species are very similar in appearance, with the larger size of 
                the greater scaup being the main distinguishing feature. Both species have a bluish 
                bill and are often called “bluebills”. The males have a purplish-green head, and 
                dark neck and chest, with a white belly. Females have a distinctive ring of white 
                feathers at the base of the bill. The speculum is white with a dark gray band at the 
                bottom. On the greater scaup, the white colour of the speculum extends out into the 
                primary feathers.`,
                image: scaup
            },
            {
                sex: "Ring-necked Duck",
                info: `The ring-neck has a distinctive white ring near the front of the bill just 
                behind the black tip. The male has a purplish-black head, neck and chest, a dark 
                back and white belly. The female has a grayish body with white belly. The speculum 
                is a solid gray colour with a darker-coloured gray band on the bottom bar.`,
                image: ringNeckedDuck
            },
            {
                sex: "Long-tailed Duck",
                info: `The plumage of the drake is black and white. The chest, back and wings are 
                black, and the sides, belly and lower tail are white. The head is white with a large 
                brownish-black patch extending from the cheek down the side of the neck. The female 
                has a similar colour pattern, only grayer in colour. The wings are black with no 
                discernible speculum.`,
                image: longTailedDuck
            },
            {
                sex: "Common Merganser",
                info: `Merganser have a distinctive narrow, reddish bill. The drake merganser has a 
                greenish-black head, white neck, and white belly. The female has a reddish head and 
                a light gray body. The speculum is white. The bill of the merganser distinguishes it 
                from other waterfowl species. The large size distinguishes the common merganser from 
                other merganser species.`,
                image: commonMerganser
            },
            {
                sex: "Hooded Merganser",
                info: `The hooded merganser or “hoodie” is the smallest merganser. The male has a black 
                back with two prominent black bars between its chest and sides. The belly plumage is 
                white, and the sides are tan. The drake also has a dark, greenish-black head with a 
                distinct hood and a fan-shaped white area. Its eyes are yellow, and the bill is black, 
                narrow and serrated, or saw-toothed. The female has a reddish head and a drab gray body.`,
                image: hoodedMerganser
            },
            {
                sex: "Common Goldeneye",
                info: `Usually known as “whistlers” for the sound its wings make while in flight. The male 
                has a distinctive white patch between the eye and bill. The head is large and a greenish 
                black colour. The back is black, and the neck, chest and belly are white. The female has a 
                brown head without a white patch, a brownish body and a white belly. The speculum is white. 
                The wing feathers above the speculum are white on the male and a mixture of white and black 
                on the female.`,
                image: commonGoldenEye
            }
        ],
        geese: [
            {
                sex: "Canada Goose",
                info: `All are similar in appearance but vary considerably in size. The sexes look alike. 
                They have a dark head and neck with distinctive white thin markings.`,
                image: canadaGeese
            },
            {
                sex: "Snow Goose",
                info: `There are two colour phases in snow goose populations: a dark or blue phase and a 
                white phase. Adults of the white phase are all-white with black wing tips. The sexes are 
                similar in appearances, but juveniles are more gray than white. Head and neck feathers 
                are usually stained with rustic orange. TIP: care must be taken not to confuse snow geese 
                with protected species like whooping cranes, pelicans or trumpeter swan.`,
                image: snowGoose
            }
        ],
        birds: [
            {
                sex: "American Woodcock",
                info: `Often associated with Ruffed Grouse with having similar habitats. The Woodcock, or 
                “timber doodle”, is a stocky, brown bird with short, rounded wings. It has dark bands on 
                the top of its head, short legs, large eyes set high and far back on the side of the head, 
                and a long slender bill. The long bill is used for probing the soil for earthworms and 
                grub.`,
                image: americanWoodcock
            },
            {
                sex: "Common Snipe",
                info: `The snipe is a brown colour, with a streaked back and brown-white striped head. The 
                legs and feet are greenish-gray to yellow-green.`,
                image: commonSnipe
            },
            {
                sex: "Mourning Dove",
                info: `The Mourning Dove is a handsome streamlined bird with a small head and long pointed 
                tail. Adult birds measure about 28-33cm in length. The plumage is grayish blue to grayish 
                brown with black spots on the wing and behind the eye. The legs are reddish pink in colour 
                in adult birds. Its flight is direct and rapid, and its wings produce a noticeable whistle 
                when the bird is in flight.`,
                image: mourningDove
            }
        ],
        gear: [
            {
                title: "Shotgun & Shells",
                info: `A 12 gauge shotgun is an excellent choice. If you are hunting with someone who cannot
                handle a 12 gauge, then a 20 gauge will do as well. You may only be able to fit 3 shells in 
                your gun at a single given time, so make sure you have your plugs. It is legal to modify your
                gun or plug while in the feild, just as long as your gun stays within the regulations. 
                Semi-automatic shotguns will give you an advantage over a pump action. This is because you 
                have a quicker recovery time after you fire a shot(no need to pump). A great shot gun is 
                the Benelli Super Vinci. It is a light, semi-automatic shotgun, that handle recoil great. It 
                is a shotgun on the more expensive end, but, in the long run it is worth it. The shotgun also
                comes along with most choke tube sizes.`,
                image1: SuperVinci
            },
            {
                title: "Shot shells",
                info: `The type of shotgun shell you'll need will depend on the size of the waterfowl you'll be 
                shooting. The typical shell length waterfowl hunters use are 3 inch shells. The shot size depends 
                on the type of duck. Teal are smaller ducks and can be hunted with a No. 4 shot while bigger ducks 
                like mallards should be hunted with a No. 2 shot. If you were to hunt Geese, you'll need BB or BBB, 
                maybe even T. Remember that you CANNOT use toxic shots like lead. You must use something like steel,
                bismuth or tungsten. These shots cannot be used with a Full or Improve-Modified choke tube. When hunting 
                with shot sizes of No. 5 and up, a Modified choke tube is highly recommended. A Full choke can be used
                with these hard pellets, but the size of the shot must be at least a No. 6 or smaller.`,
                image1: waterfowlLoad,
            },
            {
                title: "Waders",
                info: `Hip Waders are probably the most important thing for waterfowl hunters. These keep you dry when you 
                go to retrieve ducks out on the water or when you are setting up your decoy spread. If your hunting in shallow 
                water like 1 foot or so, you can get away with rubber boots, but they wont be as reliable as hip waders.`,
                image1: hipWaders
            },
            {
                title: "Head Lamps",
                info: `Headlambs are essential for setting up your decoy spread in those early mornings, and also for the clean 
                up after sunset hits. You could use flashlights as well but you typically would want both of your hands free. 
                Don't go cheap on the lights because they can cause you to miss one of your decoys during dark clean ups.`,
                image1: lamp
            },
            {
                title: "Call Lanyard",
                info: `Lanyards are also very important to hold all of your calls. Even if you just have one duck call, you 
                should still keep it on a lanyard. It give you quick access to it and prevents you fron dropping it on the 
                floor or worse, in the water.`,
                image1: lanyard
            },
            {
                title: "Calls",
                info: `You need to feel out the ducks while calling. Sometimes ducks love to hear lots of calling, or they 
                might just want a few little sounds, or other times ducks don't want any calling at all and they'll come and 
                land in your spread.`,
                image1: call
            },
            {
                title: "Gloves",
                info: `Waterproof gloves will save your fingers on cold mornings. Keep your fingers dry or you wont be 
                having a fun time. A second pair of normal gloves that aren't too bulky are good to have as well. If your
                gloves are too fluffy you won't be able to switch on/off your gun's saftey, handle your belongings, use 
                your call, or load shells into your shotgun.`,
                image1: gloves,
            },
            {
                title: "Blind Bag",
                info: `Most duck hunting is done in water, so that means everything will get wet. That's why you
                should get a good waterproof bag that will help keep your keys, wallet, phone, ammo, and licenses 
                dry. If your bag floats, then you can hunt right in a marsh or pond without a boat and have no 
                worries.`,
                image1: blindBagClosed,
                image2: blindBagOpen
            },
            {
                title: "Decoys",
                info: `Decoys are a MUST for duck hunting. You cannot make do without them. When you buy duck 
                decoys, everything usually works. The bare minimum for Decoys is at least 6 mallard or teal 
                decoys. Usually a good spread will have about 24 decoys or so. Any more than 30 decoys will 
                be a bit overkill.`,
                image1: decoys
            },
            {
                title: "Mojo",
                info: `A mojo is a mechanical decoy that is propered in the air with eletronic wings that spin. 
                This creates motion when the water is still and will attract more ducks to your spread. You only
                need 1 or 2 of these and it can be a game changer sometimes.`,
                image1: mojo,
            },
            {
                title: "Weights & Lines",
                info: `All Decoys (other than mojos) need ancors so they stay put and don't float away. You can usually 
                buy premade sets that get the job done. Depending on how deep your pond or lake is, you won't need more 
                than about 4 feet for line on your decoys.`,
                image1: weightLines,
            },
            {
                title: "Decoy Bag",
                info: `When Transporting Decoys from the truck to the water, most people use big decoy bags that can hold 
                at least a dozen. These are great but can sometimes get a little expensive. The pro for these is that you 
                can wear it as a backpack plus it will protect the paint on the decoys, making them last longer. If you 
                dont have the money for these bags you can just use a carabiner, the premade decoy strings will have a 
                loop for it and might come with one too.`,
                image1: decoyBag
            }
        ],
        methods: [
            {
                title: "Hunting in Water",
                info: `When duck hunting, you'll usually be hunting in some type of pond or lake. If you own your own boat 
                you can build youself a custom blind for the top of your boat. This will let you go in open water with 
                little bursh and still stay hidden. If you don't have a boat then you can hunt them on the shore. Here is a 
                diagram of the most typical decoys spread.`,
                note: `Always try to set up so the way is blowing from behind you, to towards the decoys. Birds like to land 
                with their face in the wind.`,
                image: huntingInWater
            }
        ]
    }

    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <article className="waterfowl">

            <MetaTags>
                <title>Waterfowl</title>
                <meta
                    name="description"
                    content={`Check us out and learn how to hunt Waterfowl like mallards, teal, and many more.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <div className="bear__first-wrapper">
                <img className="bear__back" src={background} alt="background" />
            </div>
            <AnimalNavigationBar />
            <div className="waterfowl__limit">
                <div className="waterfowl__wrapper">
                    <AnimalHeader header={mainObj.header} />
                    <AniamlIdentification identification={mainObj.puddleDucks} newTitle="Puddle Ducks" />
                    <AniamlIdentification identification={mainObj.divingDucks} newTitle="Diving Ducks" />
                    <AniamlIdentification identification={mainObj.geese} newTitle="Geese" />
                    <AniamlIdentification identification={mainObj.birds} newTitle="Birds" />
                    <AnimalGear gear={mainObj.gear} />
                    <HuntingMethods methods={mainObj.methods} />
                </div>
            </div>
        </article>
    );
}

export default WaterFowl;