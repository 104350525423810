import React from 'react';
import './HuntingMethodCard.scss';

const HuntingMethodCard = ({title, info, note, image}) => {
    return (
        <div className="hunting-method-card">
            <section>
            <h3 className="hunting-method-card__title">{title}</h3>
            <p className="hunting-method-card__info">{info}</p>
            <p className="hunting-method-card__info"><strong>NOTE:</strong> {note}</p>
            </section>
            <img className="hunting-method-card__image" src={image} alt={title} />
        </div>
    );
}

export default HuntingMethodCard;
