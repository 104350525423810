import React, { useState } from 'react';
import './Shop.scss';

import gloves from '../../Images/Shop/Hunting/decoy-gloves.jpg';
import thermalSocks from '../../Images/Shop/Hunting/thermal-socks.jpg';
import camoHoodie from '../../Images/Shop/Hunting/camo-hoodie.jpg';
import orangeHoodie from '../../Images/Shop/Hunting/orange-hoodie.jpg';
import orangeVest from '../../Images/Shop/Hunting/orange-vest.jpg';
import orangeToque from '../../Images/Shop/Hunting/orange-toque.jpg';
import orangeHat from '../../Images/Shop/Hunting/orange-hat.jpg';
import orangeHatLight from '../../Images/Shop/Hunting/orange-beanie-light.jpg';
import longJohns from '../../Images/Shop/Hunting/long-johns.jpg';
import hipWaders from '../../Images/Shop/Hunting/hip-waders.jpg';
import rubberBoots from '../../Images/Shop/Hunting/rubber-boots.jpg';
import headLamp from '../../Images/Shop/Hunting/head-lamp.jpg';
import fieldBag from '../../Images/Shop/Hunting/field-bag.jpg';
import backBack from '../../Images/Shop/Hunting/backpack.jpg';
import foldingChair from '../../Images/Shop/Hunting/folding-chair.jpg';
import heatASeat from '../../Images/Shop/Hunting/heat-a-seat.jpg';
import SDCard from '../../Images/Shop/Hunting/sd-card-16gb.jpg';
import AABattery from '../../Images/Shop/Hunting/AA-24-battery.jpg'
import cameraBag from '../../Images/Shop/Hunting/camera-bag.jpg'
import feederGuard from '../../Images/Shop/Hunting/feeder-guard.jpg'
import gameFeeder from '../../Images/Shop/Hunting/game-feeder.jpg'
import mallardDecoy6 from '../../Images/Shop/Hunting/six-mallard-decoys.jpg';
import woodDuckDecoy6 from '../../Images/Shop/Hunting/six-wood-duck-decoys.jpg';
import buffleHeadDecoy6 from '../../Images/Shop/Hunting/six-bufflehead-decoys.jpg';
import greenWingDecoy6 from '../../Images/Shop/Hunting/six-teal-decoys.jpg';
import henTurkeyDecoy from '../../Images/Shop/Hunting/turkey-hen-decoy.jpg';
import cameraStraps from '../../Images/Shop/Hunting/camera-straps.jpg';
import mallardMojo from '../../Images/Shop/Hunting/mallard-mojo.jpg';
import tealMojo from '../../Images/Shop/Hunting/teal-mojo.jpg';
import decoyBag from '../../Images/Shop/Hunting/decoy-bag.jpg';
import gooseCall from '../../Images/Shop/Hunting/goose-call.jpg';
import turkeyCall from '../../Images/Shop/Hunting/turkey-call.jpg';
import BuckGruntCall from '../../Images/Shop/Hunting/deer-grunt-call.jpg'
import lanyard from '../../Images/Shop/Hunting/lanyard.jpg';
import doeBleat from '../../Images/Shop/Hunting/doe-bleat.jpg';
import fieldKnifes from '../../Images/Shop/Hunting/field-knifes.jpg';
import bigGameBroadHead from '../../Images/Shop/Hunting/broadheads.jpg';
import turkeyBroadHead from '../../Images/Shop/Hunting/turkey-broadhead.jpg';
import gameHolder from '../../Images/Shop/Hunting/game-holder.jpg';
import sling from '../../Images/Shop/Hunting/sling.jpg';
import scentDetergent from '../../Images/Shop/Hunting/scent-detergent.jpg';
import scentDeodorant from '../../Images/Shop/Hunting/scent-detergent.jpg';
import scentDryerSheets from '../../Images/Shop/Hunting/scent-dryer-sheets.jpg';
import scentSpray from '../../Images/Shop/Hunting/scent-spray.jpg';
import scentWipes from '../../Images/Shop/Hunting/scent-wipes.jpg';
import doeUrine from '../../Images/Shop/Hunting/doe-urine.jpg';
import doeEstrus from '../../Images/Shop/Hunting/doe-estrus.jpg';
import keyWick from '../../Images/Shop/Hunting/key-wicks.jpg';
import groundBlind from '../../Images/Shop/Hunting/blind.jpg';
import blindWrap from '../../Images/Shop/Hunting/blind-wrap.jpg';
import treeStand from '../../Images/Shop/Hunting/tree-stand.jpg';
import SelfTappingScrewLadder from '../../Images/Shop/Hunting/tree-ladder.jpg';
import lifeLine from '../../Images/Shop/Hunting/life-line.jpg';
import harness from '../../Images/Shop/Hunting/harness.jpg';
import ProductCards from '../../Components/ProductCards/ProductCards';

import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';
import { v4 as uuidv4 } from 'uuid';


const Shop = () => {

    /* === apparel === */
    /* === calls === */
    /* === Cooking === */
    /* === Decoys === */
    /* === Equipment === */
    /* === Field === */
    /* === Management === */
    /* === Saftey === */
    /* === Scent === */
    /* === Tree === */
    /* === Weapons === */

    const mainList = [
        {
            title: "Waterproof Gloves",
            image: gloves,
            category: ["apparel"],
            url: "https://amzn.to/2Tue8qS"
        },
        {
            title: "Thermal Socks",
            image: thermalSocks,
            category: ["apparel", "hunting"],
            url: "https://amzn.to/3vprcuI"
        },
        {
            title: "Camo Hoodie",
            image: camoHoodie,
            category: ["apparel"], 
            url: "https://amzn.to/3iNejYZ"
        },
        {
            title: "Orange Hoodie",
            image: orangeHoodie,
            category: ["apparel", "saftey"], 
            url: "https://amzn.to/3cJYhv3"
        },
        {
            title: "Orange Vest",
            image: orangeVest,
            category: ["apparel", "saftey"], 
            url: "https://amzn.to/3vnFsEs"
        },
        {
            title: "Orange Toque",
            image: orangeToque,
            category: ["apparel", "saftey"], 
            url: "https://amzn.to/3cHxyzj"
        },
        {
            title: "Orange Hat",
            image: orangeHat,
            category: ["apparel", "saftey"], 
            url: "https://amzn.to/3vq3pen"
        },
        {
            title: "Orange Toque With Light",
            image: orangeHatLight,
            category: ["apparel", "saftey"], 
            url: "https://amzn.to/2U8NNyP"
        },
        {
            title: "Long Johns",
            image: longJohns,
            category: ["apparel"], 
            url: "https://amzn.to/3gwvDi5"
        },
        {
            title: "Hip Waders",
            image: hipWaders,
            category: ["apparel"], 
            url: "https://amzn.to/35jep2f"
        },
        {
            title: "Rubber Boots",
            image: rubberBoots,
            category: ["apparel"], 
            url: "https://amzn.to/3vjHe9y"
        },
        {
            title: "Head Lamp",
            image: headLamp,
            category: ["equipment"], 
            url: "https://amzn.to/3gqnnBg"
        },
        {
            title: "Field Bag",
            image: fieldBag,
            category: ["equipment"], 
            url: "https://amzn.to/3xmjPWu"
        },
        {
            title: "Backpack",
            image: backBack,
            category: ["equipment"], 
            url: "https://amzn.to/3cJrGoZ"
        },
        {
            title: "Folding Chair",
            image: foldingChair,
            category: ["equipment"], 
            url: "https://amzn.to/3iFCtED"
        },
        {
            title: "Heat A Seat",
            image: heatASeat,
            category: ["equipment"], 
            url: "https://amzn.to/3zv5eJZ"
        },
        {
            title: "16GB SD Card",
            image: SDCard,
            category: ["equipment", "management"], 
            url: "https://amzn.to/3wpqPBS"
        },
        {
            title: "AA Batteries",
            image: AABattery,
            category: ["equipment", "management"], 
            url: "https://amzn.to/3gp4caS"
        },
        {
            title: "Camera Bag",
            image: cameraBag,
            category: ["management"], 
            url: "https://amzn.to/3gpwK4d"
        },
        {
            title: "Camera Straps",
            image: cameraStraps,
            category: ["equipment", "management"], 
            url: "https://amzn.to/2SyMJUs"
        },
        {
            title: "Automatic Feeder",
            image: gameFeeder,
            category: ["management"], 
            url: "https://amzn.to/3xnZAaU"
        },
        {
            title: "Feeder Guard",
            image: feederGuard,
            category: ["management"], 
            url: "https://amzn.to/3xkWCUl"
        },
        {
            title: "Mallard Decoys",
            image: mallardDecoy6,
            category: ["decoys"], 
            url: "https://amzn.to/3znbrYF"
        },
        {
            title: "Green-Wing Teal Decoys",
            image: greenWingDecoy6,
            category: ["decoys"], 
            url: "https://amzn.to/3iFCIQ3"
        },
        {
            title: "Bufflehead Decoys",
            image: buffleHeadDecoy6,
            category: ["decoys"], 
            url: "https://amzn.to/2TxABDr"
        },
        {
            title: "Wood Duck Decoys",
            image: woodDuckDecoy6,
            category: ["decoys"], 
            url: "https://amzn.to/3pUxnpA"
        },
        {
            title: "Turkey Hen Decoy",
            image: henTurkeyDecoy,
            category: ["decoys"], 
            url: "https://amzn.to/3zCsGVY"
        },
        {
            title: "Green-Wing Teal Mojo",
            image: tealMojo,
            category: ["decoys"], 
            url: "https://amzn.to/2TuJGNl"
        },
        {
            title: "Mallard Decoy",
            image: mallardMojo,
            category: ["decoys"], 
            url: "https://amzn.to/3iH8Q5D"
        },
        {
            title: "Decoy Bag",
            image: decoyBag,
            category: ["equipment", "decoys"], 
            url: "https://amzn.to/3pTUKzo"
        },
        {
            title: "Goose Call",
            image: gooseCall,
            category: ["equipment", "calls"], 
            url: "https://amzn.to/3iDn3AJ"
        },
        {
            title: "Turkey Call",
            image: turkeyCall,
            category: ["equipment", "calls"], 
            url: "https://amzn.to/3wq5Mz9"
        },
        {
            title: "Lanyard",
            image: lanyard,
            category: ["equipment"], 
            url: "https://amzn.to/3zkW42G"
        },
        {
            title: "Buck Grunt Call",
            image: BuckGruntCall,
            category: ["equipment", "calls"], 
            url: "https://amzn.to/2Tu7mRN"
        },
        {
            title: "Doe Bleat",
            image: doeBleat,
            category: ["equipment", "calls"], 
            url: "https://amzn.to/2RTpeVK"
        },
        {
            title: "Field Dressing Knife",
            image: fieldKnifes,
            category: ["equipment"], 
            url: "https://amzn.to/2SDfDTg"
        },
        {
            title: "Turkey Broad Heads",
            image: turkeyBroadHead,
            category: ["equipment", "weapons"], 
            url: "https://amzn.to/2Tu7xMX"
        },
        {
            title: "Big Game Broad Heads",
            image: bigGameBroadHead,
            category: ["equipment", "weapons"], 
            url: "https://amzn.to/3zv5ysb"
        },
        {
            title: "Small Game Holder",
            image: gameHolder,
            category: ["equipment"], 
            url: "https://amzn.to/2TuK5zl"
        },
        {
            title: "Gun Sling",
            image: sling,
            category: ["equipment"], 
            url: "https://amzn.to/3pVY7Go"
        },
        {
            title: "Scent Killer Detergent",
            image: scentDetergent,
            category: ["scent control"], 
            url: "https://amzn.to/3pVY7Go"
        },
        {
            title: "Scent Killer Spray",
            image: scentSpray,
            category: ["scent control", "equipment"], 
            url: "https://amzn.to/2RTpqEs"
        },
        {
            title: "Scent Killer Dryer Sheets",
            image: scentDryerSheets,
            category: ["scent control"], 
            url: "https://amzn.to/3znbRhH"
        },
        {
            title: "Scent Killer Field Wipes",
            image: scentWipes,
            category: ["scent control", "equipment"], 
            url: "https://amzn.to/3gxwUoT"
        },
        {
            title: "Scent Killer Deotorant",
            image: scentDeodorant,
            category: ["scent control"], 
            url: "https://amzn.to/3gs0Lis"
        },
        {
            title: "Doe Urine",
            image: doeUrine,
            category: ["scent control", "equipment"], 
            url: "https://amzn.to/3xkXaJT"
        },
        {
            title: "Doe Estrus",
            image: doeEstrus,
            category: ["scent control", "equipment"], 
            url: "https://amzn.to/3xlFsGf"
        },
        {
            title: "Key Wick",
            image: keyWick,
            category: ["equipment", "scent control", "equipment"], 
            url: "https://amzn.to/3iGI9OB"
        },
        {
            title: "Ground Blind",
            image: groundBlind,
            category: ["equipment"], 
            url: "https://amzn.to/3gxt1k2"
        },
        {
            title: "Blind Wrap",
            image: blindWrap,
            category: ["equipment"], 
            url: "https://amzn.to/3cEWGXq"
        },
        {
            title: "tree stands",
            image: treeStand,
            category: ["tree stands"], 
            url: "https://amzn.to/3gqCD1e"
        },
        {
            title: "Self-Tapping Screw Steps",
            image: SelfTappingScrewLadder,
            category: ["equipment", "tree stands"], 
            url: "https://amzn.to/3vmLAg1"
        },
        {
            title: "Saftey Harness",
            image: harness,
            category: ["equipment", "saftey", "tree stands"], 
            url: "https://amzn.to/3pTVch4"
        },
        {
            title: "Life Line",
            image: lifeLine,
            category: ["equipment", "saftey", "tree stands"], 
            url: "https://amzn.to/3cHF394"
        }
    ]

    const [productList, setProducts] = useState(mainList);

    function showAll() {
        setProducts(mainList);
    }

    function filterList(key) {
        setProducts(mainList.filter((item) => {
            return (item.category.includes(key.toLowerCase()));
        }))
    }

    return (
        <div className="shop">

            <MetaTags>
                <title>CA Shop</title>
                <meta
                    name="description"
                    content={`Check out a bunch of cool and handy hunting gear! All gear on this page has been reviewed and approved by CA Wilderness`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <nav className="shop-nav">
                <button className="shop-nav__btn" onClick={showAll}>All</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Apparel") }}>Apparel</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Calls") }}>Calls</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Cooking") }}>Cooking</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Decoys") }}>Decoys</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Equipment") }}>Equipment</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Management") }}>Management</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Saftey") }}>Saftey</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Scent Control") }}>Scent control</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Tree Stands") }}>Tree Stands</button>
                <button className="shop-nav__btn" onClick={() => { filterList("Weapons") }}>Weapons</button>
            </nav>
            <section className="shop-main">
                <h2 className="shop-main__title">Recommended Products</h2>
                <div className="shop-main__product-list">
                    {productList.map((item) => {
                        return (<ProductCards title={item.title} image={item.image} link={item.url} key={uuidv4()} />)
                    })}
                    {(productList.length === 0) ? <h3>Sorry no products for this category yet :(</h3> : null}
                </div>
            </section>
        </div>
    );
}

export default Shop;
