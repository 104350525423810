import React from 'react';
import AnimalActivityCard from '../AnimalActivityCard/AnimalActivityCard';
import './AniamlActivity.scss';

const AniamlActivity = ({activity}) => {
    return (
        <section className="aniaml-activity">
            <h2 className="aniaml-activity__title">Signs of Animal Activity</h2>
            <div className="aniaml-activity__wrapper">
                {activity.map( (data)=>{
                    return(<AnimalActivityCard key={data.title} title={data.title} info={data.info} image={data.image}/>)
                })}
            </div>
        </section>
    );
}

export default AniamlActivity;
