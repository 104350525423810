import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './FishingNavigationBar.scss';

import bassIcon from '../../Images/FishingPictures/index/bass-icon.jpg';
import panfishIcon from '../../Images/FishingPictures/index/panfish-icon.jpg';
import pikeIcon from '../../Images/FishingPictures/index/pike-icon.jpg';
import troutIcon from '../../Images/FishingPictures/index/trout-icon.jpg';
import walleyeIcon from '../../Images/FishingPictures/index/walleye-icon.jpg';

const FishingNavigationBar = () => {
    const [drop, setDrop] = useState(false);

    return (
        <div className="fishingNavigationBar">

            <div className="fishingNavigationBar__drop-lines" onClick={() => { setDrop(!drop)}}>
                <div className="fishingNavigationBar__drop-line"></div>
                <div className="fishingNavigationBar__drop-line"></div>
                <div className="fishingNavigationBar__drop-line"></div>
            </div>

            <div className="fishingNavigationBar__wrapper" onClick={() => { setDrop(false)}}>
                <nav className="fishingNavigationBar__drop-list" style={{ display: (drop) ? "flex" : "none" }}>
                    <Link to="/fishing/bass" className="fishingNavigationBar__link">
                        <img className="fishingNavigationBar__img" src={bassIcon} alt="Bass fishing" />
                        <h4 className="fishingNavigationBar__title">Bass</h4>
                    </Link>
                    <Link to="/fishing/panfish" className="fishingNavigationBar__link">
                        <img className="fishingNavigationBar__img" src={panfishIcon} alt="panfish fishing" />
                        <h4 className="fishingNavigationBar__title">Pan Fish</h4>
                    </Link>
                    <Link to="/fishing/northern-pike" className="fishingNavigationBar__link">
                        <img className="fishingNavigationBar__img" src={pikeIcon} alt="Nothern Pike Fishing" />
                        <h4 className="fishingNavigationBar__title">Northern Pike</h4>
                    </Link>
                    <Link to="/fishing/trout" className="fishingNavigationBar__link">
                        <img className="fishingNavigationBar__img" src={troutIcon} alt="Trout Fishing" />
                        <h4 className="fishingNavigationBar__title">Trout</h4>
                    </Link>
                    <Link to="/fishing/walleye" className="fishingNavigationBar__link">
                        <img className="fishingNavigationBar__img" src={walleyeIcon} alt="Walleye Fishing" />
                        <h4 className="fishingNavigationBar__title">Walleye</h4>
                    </Link>
                </nav>
            </div>
        </div>
    );
}


export default FishingNavigationBar;
