import React from 'react';
import './Overlay.scss';

const Overlay = ({ display, close }) => {

    function closing() {
        window.removeEventListener('resize', window, false);
        window.removeEventListener('scroll', window, false);
        close();
    }

    function windowMovement() {
        const docDOM = document.querySelector(".overlay");
        if (docDOM) {
            try{
            docDOM.style.top = window.pageYOffset + "px";
            docDOM.style.left = window.pageXOffset + "px";
            }catch(e){
                console.log("No Dom: " + e);
            }
        }
    }

    window.addEventListener("scroll", windowMovement);
    window.addEventListener("resize", windowMovement);

    return (
        <div
            style={{ display: (display) ? "block" : "none" }}
            className="overlay"
            onClick={closing}
        >
        </div>
    );

}

export default Overlay;
