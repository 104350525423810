import React from 'react';

const H3NText = ({title, info}) => {
    return (
        <div>
            <h3>{title}</h3>
            <p>{info}</p>
        </div>
    );
}

export default H3NText;
