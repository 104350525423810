import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import H3NText from '../H3NText/H3NText';

const FishIdentification = ({ indentifying }) => {
    return (
        <section>
            <h2>Identifying the Fish</h2>
            {
                indentifying.map((index) => { return <H3NText key={uuidv4()} title={index.title} info={index.info}/>})
            }
        </section>
    );
}

export default FishIdentification;
