import './AnimalHeader.scss';
import React from 'react';

const AnimalHeader = ({header}) => {
    return (
        <section className="animal-header">
            <h2 className="animal-header__title">{header.title}</h2>
            <p className="animal-header__intro">{header.info}</p>
        </section>
    );
}

export default AnimalHeader;
