import React from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from 'axios';

import "./StripeForm.scss";

const StripeForm = ({ setError, amountCent, history }) => {
    const stripe = useStripe();
    const elements = useElements();
    let canSubmit = true;

    const handleSubmit = async event => {
        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });

        if (!error && canSubmit) {
            canSubmit = false;
            const { id } = paymentMethod;
            axios.post("/donation/charge", { id, amount: (amountCent) })
                .then((response) => {
                    canSubmit = true;
                    history.push(`/thank-you/${amountCent/100}`);
                })
                .catch((error) => {
                    canSubmit = true;
                    setError(error.response.data);
                })

        }
    }

    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: 'black',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                color: 'black'
            },
        },
    };

    return (
        <form onSubmit={handleSubmit} className="StripeForm">
            <CardElement className="StripeForm__input" options={CARD_OPTIONS} />
            <button type="submit" disabled={!stripe} className="StripeForm__btn">Donate</button>
        </form>
    )
}

export default StripeForm;
