import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AniamlIdentification from '../../../Components/AniamlIdentification.js/AniamlIdentification';
import AnimalGear from '../../../Components/AnimalGear/AnimalGear';
import AnimalHeader from '../../../Components/AnimalHeader/AnimalHeader';
import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';
import AnimalSenses from '../../../Components/AnimalSenses/AnimalSenses';

import './Deer.scss';

import male from '../../../Images/HuntingPictures/Deer/Deer_Buck.jpg';
import female from '../../../Images/HuntingPictures/Deer/Deer_Doe.jpg';
import youth from '../../../Images/HuntingPictures/Deer/Deer_Fawn.jpg';
import AnimalBehaviour from '../../../Components/AnimalBehaviour/AnimalBehaviour';

import fairEye from '../../../Images/HuntingPictures/senses/fair/sensesEyeSightFair.png';
import goodSmell from '../../../Images/HuntingPictures/senses/good/sensesSmellGood.png';
import goodHearing from '../../../Images/HuntingPictures/senses/good/sensesHearingGood.png';

import remington783 from '../../../Images/HuntingPictures/Gear/Remington783Bolt.jpg';
import rifleBullets from '../../../Images/HuntingPictures/Gear/win243G95.png';
import remington870 from '../../../Images/HuntingPictures/Gear/Remington87012G.jpg';
import shotgunShells from '../../../Images/HuntingPictures/Gear/00BuckShot.jpg';
import crossbow from '../../../Images/HuntingPictures/Gear/AXE360Crossbow.jpg';
import broadhead from '../../../Images/HuntingPictures/Gear/crossbowBroadHeads.jpg';
import AniamlActivity from '../../../Components/AniamlActivity/AniamlActivity';

import DeerDroppings from '../../../Images/HuntingPictures/Deer/DeerDroppings.jpg';
import DeerGameCamera from '../../../Images/HuntingPictures/Deer/DeerGameCamera.jpg';
import DeerScraping from '../../../Images/HuntingPictures/Deer/DeerScraping.jpg';
import DeerTracks from '../../../Images/HuntingPictures/Deer/DeerTracks.jpg';
import HuntingMethods from '../../../Components/HuntingMethods/HuntingMethods';

import treeStand from '../../../Images/HuntingPictures/methods/treeStand.jpg';
import deerFeeder from '../../../Images/HuntingPictures/methods/deerFeeder.jpg';
import groundBlind from '../../../Images/HuntingPictures/methods/groundBlind.jpg';
import AnimalCalling from '../../../Components/AnimalCalling/AnimalCalling';

import deerBleat from '../../../Audio/Deer/deer_bleat.mp3';
import deerGrunt from '../../../Audio/Deer/deer_grunt.mp3';
import deerHardSpar from '../../../Audio/Deer/deer_hard_spar.mp3';
import deerTendingGrunt from '../../../Audio/Deer/deer_tending_grunt.mp3';
import deerSnortWheeze from '../../../Audio/Deer/deer_snort_wheeze.mp3';
import HunterOrange from '../../../Components/HunterOrange/HunterOrange';

import background from '../../../Images/GeneralWebsiteArt/hunting_page_background.jpg';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const Deer = () => {

    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const deerObj = {
        header: {
            title: "White-Tailed Deer",
            info: "Before you head out for a deer hunt, there a few important things to keep in mind. Firstly, you must ensure that deer season is open in your area. Next, make sure you have the proper tags, and are aware of what animals you can shoot. Lastly, make sure you know how to hunt properly in order to maximize your chances of killing a deer! For instance, understanding a deer’s senses and learning how they react in different conditions is important and useful."
        },
        identification: [
            {
                sex: "Buck",
                info: "A Buck (male deer) has antlers on his head. They will lose their antlers once a year. The shedding process lasts between two to three weeks and then new antlers will regenerate throughout the summer season.",
                image: male
            },
            {
                sex: "Doe",
                info: "A Doe (female Deer) is usually smaller than a Buck. They will sometimes be spotted with fawns (young Deer).",
                image: female
            },
            {
                sex: "Fawn",
                info: "Fawns (young Deer) are very small and spotted. They have wobbly looking legs and weigh approximately 2 to 4 kg at birth. Fawns are born in late spring.",
                image: youth
            }
        ],
        behaviour: [
            {
                title: "",
                info: "Deer feed most heavily during mornings and evening hours. Deer spend the majority of their time bedded, but generally, once per 30 minutes, deer will stand and stretch and they may urinate or defecate before laying back down. Their habits vary depending On the season, as deer spend less time bedded during the rut and more time bedded during winter."
            },
            {
                title: "The Rut",
                info: "During the Rut, Doe become estrus. Doe estrous is the urine of a Doe that is in heat, or ready to breed. This will attract most Bucks in the area. Doe will usually bed in the thickest of cover to avoid aggressive Bucks. Unlike Doe, Bucks are innately aggressive and spend most of their time searching for an estrus Doe. Bucks will fight off all other Bucks if they try to steal their Doe. The Rut commonly begins in early November, but occasionally can begin in late October."
            }
        ],
        senses: [
            {
                title: "Vision",
                info: "A Deer’s sense of sight is poor. They can see blue colors and probably even ultraviolet (UV) light. However, green, orange, and red appear to them as shades of gray. A Deer won't be able to pick up a hunter’s movement if it is slow and subtle, but anything fast and abrupt would catch the deer’s attention.",
                image: fairEye
            },
            {
                title: "Hearing",
                info: "Deer Have an exellent sense of hearing. They can easily detect the faintest of sounds. By looking at a Deer’s ears, you can determine whether they are picking up on anything or not. It is always best to be as quiet as you possibly can before, during and after your hunt. The honk from your car when pressing the lock on your keys, could ruin the hunt before it begins!",
                image: goodHearing
            },
            {
                title: "Smell",
                info: "Deer have an exellent sense of smell. Deer can usually smell a human, that is not making any attempt to hide its odour, at least 1/4 mile away. Washing your clothes in odour free detergent, is usually sufficient. Prior to heading into the field, a scent kill spray is always a good idea to minimize the risk of a deer detecting your smell.",
                image: goodSmell
            }
        ],
        gear: [
            {
                title: "Rifle (Center Fire Only)",
                info: `A good hunting rifle for begginers is the Remington 783 Bolt Action
                Rifle. You can get this rifle in a .243 Winchester Cartridge which is a
                great light caliber, for beginner deer hunters. You should use at least
                a 90-100 grain bullets when hunting deer or similar game.`,
                image1: remington783,
                image2: rifleBullets
            },
            {
                title: "Shotgun (No smaller than 20 gauge)",
                info: `A 12 gauge shotgun is recommended, along with 3 or 2 3/4 inch,
                00Buck shots, and improved cylinder choke tube. 00 Buckshots (9-15 pellets)
                are recommended because you will have a better chance of hitting the Deer.
                You can also use slugs, but the slug projectile is usually harder to hit
                deer with. If your shotgun has a scope, then you should consider slugs.
                Shotgun with rifled barrels should only be shooting Sabot Slugs, slugs without
                lines on the side. If you have a smoothbore barrel, then your shotgun should be
                shooting Rifled Slugs. A good starter shotgun that's great for many different
                types of hunting, the Remingtion 870 is a good, reliable, cheap shotgun that will
                get the job done.`,
                image1: remington870,
                image2: shotgunShells
            },
            {
                title: "Bows & Crossbows",
                info: `Usually bows and corssbow are in season all year around, depending on
                the WMU you hunt in. This being said, if you plan on doing a lot of hunting,
                a bow or corss bow is definitely worth your money. One brand that makes outstanding
               cross bows is Excalibur. Their cross bow called the AXE 340 is highly recommended 
               for all levels of deer hunters. It is very light and has crazy firing power. While 
               shooting a cross bow, you need to have at broad-head at the end of your bolt. The 
               broad-head must have 2 sharp cutting edges and must be 22mm or larger.`,
                image1: crossbow,
                image2: broadhead
            },
        ],
        activity: [
            {
                title: "Droppings",
                info: "Deer droppings usually look like bullet-shaped pellets that have an indentation on one end and point on the other.",
                image: DeerDroppings
            },
            {
                title: "Tracks",
                info: "The shape of a deer's hooves is a split heart shape. The pointed end is forward. Most tracks are between 2 and 3 inches long on relatively hard ground.",
                image: DeerTracks
            },
            {
                title: "Scrapings",
                info: "Caused by a male deer rubbing his forehead and antlers against the base of a tree to leave his scent. These are easy to spot in areas with high deer populations and can be used by hunters to determine ideal locations for hunting.",
                image: DeerScraping
            },
            {
                title: "Game Cameras",
                info: "A trail cam will also give you a pretty good idea of what Deer are in the area. They can aid you when figuring out what habits the deer have. A trail cam let's you create a census of deer in your area. Moultrire® Cameras are recommended. They are reliable and provide great results",
                image: DeerGameCamera
            }
        ],
        methods: [
            {
                title: "Tree Stands",
                info: `Hunting out of a tree stand is usually the way to go.
                Deer have a great sense of smell so being 15 feet in the air
                will help with scent control. A good tree stand is usually
                15 to 25 feet high, but the higher above the Deer's sightline,
                the more advantageous it is for the hunter. When setting up your treestand,
                don't set up in a naked tree. A tree with some branchs are key.
                Without any cover in the tree, you'll be spotted easily by any
                animal.`,
                note: `Make sure to always wear a safety harness. It doesn't matter
                if your hunting or setting up the stand, it is always possible to
                fall out of the tree stand or injure yourself`,
                image: treeStand
            }, {
                title: "Bait",
                info: `One of the best ways to bait deer is to use corn.
                You can go to your local feed store or farmer and buy a
                few 20kg bags of whole corn. Then, you can either use
                an automatic feeder, which is ideal for continuously feeding in an area of an extended period of time, or you can just
                dump it in a pile. After a week or so, try to figure out
                where the deer come from when they eat the food. Once you
                figure their path, you can position yourself in a way where you
                will have a stronger chance to get a deer`,
                note: `You can use many other tools to attract deer like:
                apples, carrots, oats, peanut butter, mineral blocks, and many more!`,
                image: deerFeeder
            }, {
                title: "Ground Blinds",
                info: `Ground blinds are the easiest to set up, and hide you the best!
                You should set up your blinds minimum two weeks or so in advance, just to let the
                deer adapt to the change in the environment. When setting up ground
                blinds, make sure you don't set them up too close where you think you'll
                be spotting a deer. The ideal distanse is about 30 yards (90 feet). At
                this distance you are close enough for a shoot, but also far enough to not reveal yourself to the deer. Keep in mind if you are hunting with an accurate
                rifle or shotgun, you could set up over 60 yards (180 feet) away to
                increase your chances of staying hidden.`,
                note: `When setting up blinds, try to make them blend in as much as
                possible. Blinds that stick out like a sore thumb could spook deer!`,
                image: groundBlind
            }
        ],
        calling: {
            info: `Any time you call, keep the direction of the wind in mind. Usally deer
            will attempt to move down wind of the sound, to figure out who it is. If the
            wind is blowing in the complete opposite direction from where the deer are,
            then there is no need to worry as much, but, always keep an eye in the
            direction of the wind. Being high up in a tree stand or a hill, usually helps
            with deer being down wind of you. Calling could give away your location so never
            call when the deer is looking directly towards you, and within a reasonable
            shooting distanse.`,
            calls: [
                {
                    title: "Bleat",
                    info: `When you call using a bleat, you are imitating a doe or fawn. This
                    sound will attract both bucks and does. This call is good to use all year
                    during deer season. You should use this when you see a deer in the distanse
                    and want to draw it in. You should use this call about every 25-30 mins, incase
                    there is a deer just out of view that you could possible draw in. If you have a
                    bleat that sounds more higher pitched, a doe will will think it is a fawn.
                    Their mother instincts will kick in and they will try to search for the fawn`,
                    sound: deerBleat
                },
                {
                    title: "Grunt",
                    info: `When you call using a grunt, you are imitating a buck. This
                    sound will attract both bucks and does. This call is good to use all year
                    during deer season. You should use this when you see a deer in the distanse
                    and want to draw it in. If you don't see any deer, then you can grunt every
                    30-40 mins, incase theres a deer just out of view that you could possible
                    draw in.`,
                    sound: deerGrunt
                },
                {
                    title: "Tending Grunt",
                    info: `Tending grunts work best during the Rut. This call imitates a deer
                    mating with another deer. You should use this call when there are no deer
                    in a near, visiable distance. This call will attract any dominate bucks in
                    the area looking to take part in breeding. Use this call every 50-70 mins.`,
                    sound: deerTendingGrunt
                },
                {
                    title: "Sparing",
                    info: `
                    Sparing is a great call to use all year around. The sparing sound
                    imitates two bucks fighting. In the early and late sessions,
                    a soft spar, small to medium noise is best. During the Rut, you
                    should try to make this sparing sound as loud as possible, because that's
                    when the bucks fight over breeding mates. This sound will draw in both
                    curious and dominate bucks to the area looking for the action. Do this every
                    50-70 mins.`,
                    sound: deerHardSpar
                },
                {
                    title: "Snort Wheeze",
                    info: `
                    A Snort Wheeze is an intimidation tactic that bucks use against other
                    bucks. This call should only be used during the rut while deer are all competing
                    amongst each other for mates. This works great against mature, dominate bucks
                    that control the area. Keep in mind this call is a hit or miss. Some deers are much 
                    more aggressive and will charge into the area while other deer, will get scared and 
                    run in the other direction. Use this call as a last resort, when there's a buck near 
                    you leaving the location.`,
                    sound: deerSnortWheeze
                }
            ]
        },
        orange: {
            info: `All huntersre, including bow hunters, during a gun season for deer,
            elk or moose, are required to wear hunter orange.`
        }
    }

    return (
        <article className="deer">
            <MetaTags>
                <title>White Tailed Deer</title>
                <meta
                    name="description"
                    content={`Check us out and learn about White Tailed Deer and how to hunt them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <div className="bear__first-wrapper">
                <img className="bear__back" src={background} alt="background" />
            </div>
            <AnimalNavigationBar />
            <div className="deer__limit">
                <div className="deer__wrapper">
                    <AnimalHeader header={deerObj.header} />
                    <AniamlIdentification identification={deerObj.identification} />
                    <AnimalBehaviour behaviour={deerObj.behaviour} />
                    <AnimalSenses senses={deerObj.senses} />
                    <AnimalGear gear={deerObj.gear} />
                    <AniamlActivity activity={deerObj.activity} />
                    <HuntingMethods methods={deerObj.methods} />
                    <AnimalCalling calling={deerObj.calling} />
                    <HunterOrange animalOrange={deerObj.orange.info} />
                </div>
            </div>
        </article>
    );
}

export default Deer;
