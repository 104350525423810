import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


import './FindWMU.scss';
import WMUTableRow from '../../Components/WMUTableRow/WMUTableRow';
import Overlay from '../../Components/Overlay/Overlay';
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const FindWMU = () => {

    const [cityList, setList] = useState([]);
    const [cityFilter, setFilter] = useState("");
    const [showOverlay, toggleOverlay] = useState(false);

    useEffect(() => {
        axios.get("/city/all")
            .then((response) => {
                setList(response.data);
            })
    }, []);

    function setOverlayValues() {
        toggleOverlay(true);
    }

    function closeOverlay() {
        toggleOverlay(false);
    }


    return (
        <>
            <article className="find-wmu" onScroll={() => { console.log("HERE") }}>

                <MetaTags>
                    <title>Finding Your WMU</title>
                    <meta
                        name="description"
                        content={`Check out our handy tool for finding your WMU. You can also see what cities allow Sunday gun hunts.`}
                    />
                    <link rel="icon" href={iconImage} type="image/png" />
                    <meta property="og:image" content={iconImage} />
                </MetaTags>

                <section>
                    <h2 className="find-wmu__title">Find Your Wildlife Management Unit</h2>
                    <div className="find-wmu__info-wrapper">
                        <label className="find-wmu__label">Enter your desired Township or WMU</label>
                        <input type="text" className="find-wmu__input" value={cityFilter} onChange={(e) => { setFilter(e.target.value) }} />
                    </div>
                </section>
                <div>
                    <table className="find-wmu__table">
                        <thead>
                            <tr>
                                <td className="find-wmu__table-head">Township</td>
                                <td className="find-wmu__table-head">WMUs</td>
                                <td className="find-wmu__table-head">Sunday Gun Hunt</td>
                            </tr>
                        </thead>
                        <tbody>
                            {cityList.filter((township) => { return ((township.City.toLowerCase().includes(((cityFilter) ? cityFilter.toLowerCase() : null))) || (township.WMU.toString().toLowerCase().includes((cityFilter) ? cityFilter.toLowerCase() : null)) || (!cityFilter)) }).map((data) => {
                                return <WMUTableRow key={uuidv4()} city={data.City} wmus={data.WMU} sunday={data.SundayGunHunts} overlay={setOverlayValues} />
                            })}
                        </tbody>
                    </table>
                </div>
            </article>
            <Overlay display={showOverlay} close={closeOverlay} />
        </>
    );
}

export default FindWMU;
