import React from 'react';
import './AnimalSensesCard.scss';

const AnimalSensesCard = ({ title, info, image }) => {
    return (
        <section className="AnimalSensesCard">
            <div className="AnimalSensesCard__image-wrapper">
                <img className="AnimalSensesCard__image" src={image} alt={title} />
            </div>
            <div>
                <h3 className="AnimalSensesCard__title">{title}</h3>
                <p className="AnimalSensesCard__info">{info}</p>
            </div>
        </section>
    );
}

export default AnimalSensesCard;


/**
 *     if (title.toLowerCase() === "vision") {
        if (image.toLowerCase() === "good") {
            cardImage = goodEye;
        }
        else if (image.toLowerCase() === "fair") {
            cardImage = fairEye;
        }
        else {
            cardImage = badEye;
        }
    }
    else if (title.toLowerCase() === "hearing") {
        if (image.toLowerCase() === "good") {
            cardImage = goodHearing;
        }
        else if (image.toLowerCase() === "fair") {
            cardImage = fairHearing;
        }
        else {
            cardImage = badHearing;
        }
    }
    else {
        if (image.toLowerCase() === "good") {
            cardImage = goodSmell;
        }
        else if (image.toLowerCase() === "fair") {
            cardImage = fairSmell;
        }
        else {
            cardImage = badSmell;
        }
    }

 * 
 */