import React from 'react';
import AnimalGearCard from '../AnimalGearCard/AnimalGearCard';
import './AnimalGear.scss';

const AnimalGear = ({gear}) => {
    return (
        <section className="animalGear">
            <h2 className="animalGear__title">Gear</h2>
            <div className="animalGear__list">
                {gear.map((data) => {
                    return(<AnimalGearCard key={data.title} title={data.title} info={data.info} image1={data.image1} image2={data.image2}/>)
                })}
            </div>
        </section>
    );
}

export default AnimalGear;
