import React from 'react';
import FishFind from '../../../Components/FishFind/FishFind';
import FishIdentification from '../../../Components/FishIdentification/FishIdentification';
import FishingNavigationBar from '../../../Components/FishingNavigationBar/FishingNavigationBar';
import FishIntro from '../../../Components/FishIntro/FishIntro';
import FishTackle from '../../../Components/FishTackle/FishTackle';
import './Bass.scss';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const Bass = () => {

    const fish = {
        intro: {
            title: `Bass Fishing`,
            info: `Bass fishing is the most popular fishing in all of Ontario.
            There are countless way to fish them, and setting the hook in a
            big bass is the best feeling in the world!`
        },
        indentifying: [
            {
                title: `Largemouth Bass`,
                info: `Largemouth Bass can be between 9 to 22 inches. Their upper jaw
                will extend beyond pass their eye. The most obvious way to identify
                them, is the dark broken horizonatal stripe on both sides of the fish.
                The Largemouth bass looks very similar to the Smallmouth bass and Rock
                bass`
            },
            {
                title: `Smallmouth Bass`,
                info: `Smallmouth Bass can be between 9 to 20 inches. Their upper jaw
                does not extend pass their eye. The most obvious way to identify
                them, is the dark vertical stripes alonge both sides of the fish.`
            }
        ],
        tackle: {
            info: `There are many different types of crank baits, jigs, spinners, and plastics
            that will work on Bass. Each one is used in differen't enviernments and 
            some have more advantages than otherss.`,
            tackleList: ['Jigs', 'Spinner Baits', 'Crank Baits', 'Plastics', 'Minnows', 'Frogs', 'Swim Baits']
        },
        findFish: {
            info: [
                {
                    title: "Largemouth Bass",
                    info: `These fish like to hide. So try finding a body of water that has lots of cover,
                    like weeds, or trees in the water. They warm, slow moving or still water as well.`
                },
                {
                    title: "Smallmouth Bass",
                    info: `These fish like water with little cover. Can usually be found in areas that are rocky
                    with little to no vegetation. They are also usually often found more in clear water
                    than murky water.`
                }
            ]
        }
    };

    return (
        <article className="bass">
            <MetaTags>
                <title>Bass Fishing</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Bass and how to fish them. 
                    Check out how to find Bass, and also what baits and tackle are great to 
                    use when targeting them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <FishingNavigationBar />
            <div className="bass__wrapper">
                <FishIntro title={fish.intro.title} info={fish.intro.info} />
                <FishIdentification indentifying={fish.indentifying} />
                <FishTackle info={fish.tackle.info} tackleList={fish.tackle.tackleList} />
                <FishFind findFish={fish.findFish} />
            </div>
        </article>
    );
}

export default Bass;
