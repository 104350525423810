import React from 'react';
import './AnimalBehaviour.scss';
import { v4 as uuidv4 } from 'uuid';

const AnimalBehaviour = ({ behaviour }) => {
    return (
        <section className="animal-behaviour">
            <h2 className="animal-behaviour__title">Behaviour</h2>
            {behaviour.map((data) => {
               return( <section className="animal-behaviour__card" key={uuidv4()}>
                    {(data.title ? <h3 className="animal-behaviour__card__title">{data.title}</h3> : null)}
                    <p className="animal-behaviour__card__info">{data.info}</p>
                </section>)
            })}
        </section>
    );
}

export default AnimalBehaviour;
