import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AniamlIdentification from '../../../Components/AniamlIdentification.js/AniamlIdentification';
import AnimalHeader from '../../../Components/AnimalHeader/AnimalHeader';
import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';
import AnimalSenses from '../../../Components/AnimalSenses/AnimalSenses';
import HuntingMethods from '../../../Components/HuntingMethods/HuntingMethods';

import './SmallGame.scss';

//animal pictures
import cottintail from '../../../Images/HuntingPictures/SmallGame/cotton-tail_rabbit.jpg';
import snowshoeHair from '../../../Images/HuntingPictures/SmallGame/snowshoe_rabbit.jpg';
import graySquirrel from '../../../Images/HuntingPictures/SmallGame/gray-squirrel.jpg';
import foxSquirrel from '../../../Images/HuntingPictures/SmallGame/fox-squirrel.jpg';
import blackSquirrel from '../../../Images/HuntingPictures/SmallGame/black-squirrel.jpg';
import redSquirrel from '../../../Images/HuntingPictures/SmallGame/red-squirrel.jpg';
import ruffedGrouse from '../../../Images/HuntingPictures/SmallGame/ruffed-grouse.jpg';
import sharpTailGrouse from '../../../Images/HuntingPictures/SmallGame/sharp-tailed-grouse.jpg';
import spruceGrouse from '../../../Images/HuntingPictures/SmallGame/spruce-grouse.jpg';

//sense pictures
import goodEyes from '../../../Images/HuntingPictures/senses/good/sensesEyeSightGood.png';
import goodHearing from '../../../Images/HuntingPictures/senses/good/sensesHearingGood.png';
import goodSmell from '../../../Images/HuntingPictures/senses/good/sensesSmellGood.png';
import fairHearing from '../../../Images/HuntingPictures/senses/fair/sensesHearingFair.png';
import fairSmell from '../../../Images/HuntingPictures/senses/fair/sensesSmellFair.png';
import badSmell from '../../../Images/HuntingPictures/senses/bad/sensesSmellBad.png';

//hunting methjods pictures
import slowWalk from '../../../Images/HuntingPictures/methods/slow-walk.png';
import spiralWalk from '../../../Images/HuntingPictures/methods/spiral-walk.jpg';
import jumpingRabbits from '../../../Images/HuntingPictures/methods/jumping-rabbits.jpg';
import bait from '../../../Images/HuntingPictures/methods/deerFeedCorn.jpg';
import woodedLand from '../../../Images/HuntingPictures/methods/wooded-land.jpg';
import waiting from '../../../Images/HuntingPictures/methods/sit-and-wait.jpg';
import walnuts from '../../../Images/HuntingPictures/methods/black-walnuts.jpg';
import AnimalGear from '../../../Components/AnimalGear/AnimalGear';

//gear pictures
import smallGameShotgun from '../../../Images/HuntingPictures/Gear/small-game-shotguns.png'
import smallGameShot from '../../../Images/HuntingPictures/Gear/small-game-shot.jpg'
import rouger22 from '../../../Images/HuntingPictures/Gear/rouger22.jpg'
import subsonic from '../../../Images/HuntingPictures/Gear/subsonic-rounds.jpg'
import pelletGun22 from '../../../Images/HuntingPictures/Gear/pelletGun22.jpg'
import pellets from '../../../Images/HuntingPictures/Gear/pellets.png'
import HunterOrange from '../../../Components/HunterOrange/HunterOrange';

import background from '../../../Images/GeneralWebsiteArt/hunting_page_background.jpg';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

//animals objects
const SmallGame = () => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const mainObj = {
        header: {
            title: "Small Game",
            info: "You need a small game license for all of these animals. Hunting small game is great for group hunting or hunting with your dogs."
        },
        orange: {
            info: `Wearing orange is only required if there is a Deer, Moose or Elk gun seasson open, 
            otherwise you are not required to wear hunter orange but it is highly recommended if 
            hunting with a group.`
        }
    }

    const rabbitObj = {
        header: {
            title: "Rabbit",
            info: "Rabbit hunting is a great sport and there are so many different ways to hunt them. Hands down they are one of the best tasting small game!"
        },
        identification: [
            {
                sex: "Cotton-Tail",
                info: `The Cottontail typically look light brown or grayish in colour and a 
                little more than a foot in length for most species. As the weather gets 
                colder, their fur tends to darken and get longer. They are extremely prevalent 
                and is the most common hare found in north america.`,
                image: cottintail
            },
            {
                sex: "Snowshoe",
                info: `The Snowshoe’s have wide hind feet and a tail that leaves tracks 
                similar to a snowshoe. Its fur changes from white to brown depending on 
                the seasons. They are comfortably bound through freezing winter 
                temperatures, which is where they prefer to live.`,
                image: snowshoeHair
            }
        ],
        senses: [
            {
                title: "Vision",
                info: `A rabbit’s field of vision is immense. He has large eyes that are 
                located on the sides and upper part of the head, enabling each eye to see 
                more than one half of a circle. This allows the rabbit to see nearly 360 
                degrees, as well as far above their head. Despite their large field of vision, 
                rabbits have reduced depth perception as well as a limited degree of close-up 
                vision.`,
                image: goodEyes
            },
            {
                title: "Hearing",
                info: `Hearing is a rabbit’s most vital sense, hence the large upright ears. 
                Used primarily to detect predators as well as to help perceive the area around 
                them. They are able to hear up to 2 miles away. When relaxed their ears will 
                lie along their back but when alerted they become erect and move forward/backward 
                to pinpoint the source of the danger.`,
                image: goodHearing
            },
            {
                title: "Smell",
                info: `A good sense of smell, with up to fifty million receptor cells in their nose 
                compared to our six million. With this very sensitive sense, rabbits can smell 
                danger before ever seeing it. Their sense of smell is much more sensitive in areas 
                with more moisture in the area.`,
                image: goodSmell
            }
        ],
        methods: [
            {
                title: "Slow Walk",
                info: `Taking a slow walk in a feild, trails, or logging roads is a great way to find rabbits. 
                You shold walk slow and quietly, rabbits have great hearing. When you see a rabbit, don't make 
                sudden movents because that will scare them and they will run off. If they do run off, stay ready.
                The rabbit will usually run about 10-20 yards, stop and look behind them for a second look. When 
                this happens you only have about 3 seconds to make your shot. Make it count.`,
                note: `When hunting Snowshoe Hair, keep an eye out for their black eye in the snow, this will 
                stick out once you know what it looks like.`,
                image: slowWalk
            }, {
                title: "Spiral Walk",
                info: `A great way to hunt is to find a big feild, and walk in a spiral motion that gets bigger
                and bigger. This will cover more ground than walking in a stright line. Remember, rabbits only
                live within a 10 acre area.`,
                note: `Make sure to walk slow and stay in the ready position if hunting with 
                a firearm.`,
                image: spiralWalk
            }, {
                title: "Jumping Rabbits",
                info: `Rabbits love to stay in thick cover. This can be a really thick bush, 
                pile of skids, or under old vechiles. If you see rabbit tracks or droppings
                near any of these thick covers, it is a clear indicator they are probably 
                in the area. What you need to do is absolutly disture the area, wheather thats
                jumping on the pile of debre or shaking the bush really aggressivly, this will
                make the rabbit feel it needs to escape the area. When the rabbit makes a run for it
                that is your shot and it might not stop to look back.`,
                note: `This work best with another hunter ready to take the shot. A shotgun is your 
                best option for this strategy.`,
                image: jumpingRabbits
            }, {
                title: "Baiting",
                info: `A really easy way to get rabbits is to set bait and wait. Corn, carrots, lettuce, 
                or anything green, rabbits will come and eat it. If you have a ground blind set up or a decent 
                distance from the pile, you should have a great shot at this mehod. Use a .22 longrifle or airgun
                when doing this because you will have a steady clear shot on the rabbit. This will save more 
                meat than a spread of pellets from a shotgun.`,
                note: `Setting up a ground blind or makeshift blind with a big blanket will help you a lot.
                You will be able to set up a way to have a more clear shot, as well as stay hidden better than 
                without one`,
                image: bait
            }
        ]
    }

    const SquirrelObj = {
        header: {
            title: "Squirrels",
            info: "Squirrel hunting is a fun sport and there are so many different ways to hunt them. They are one of the more easier small game to hunt."
        },
        identification: [
            {
                sex: "Gray Squirrel",
                info: `Gray squirrels have a grayish back and sides, and whitish or brownish undersides. 
                A solid black color phase predominates in some areas. Gray Squirrel's have a big bushy tail
                with a whitish boarder around it. Adults measure 35cm to 54cm from head to tail and can weigh 
                from 340 grams to 680 grams.`,
                image: graySquirrel
            },
            {
                sex: "Fox Squirrel",
                info: `Fox squirrels are dull orange on the underside, the tips of the ears and the tops of their 
                tail. Some varieties are black or gray. adults can measure from 45cm to 70cm in total length. The 
                tail being 20cm to 33cm long, and can weight in range from 500 grams to 1kg. There is no differences 
                in sex in both size or appearance.`,
                image: foxSquirrel
            },
            {
                sex: "Black Squirrels",
                info: `Black squirrels are completely black. Adults measure 35cm to 54cm from head to tail and can weigh from 340 grams to 680 grams.`,
                image: blackSquirrel
            },
            {
                sex: "Red Squirrels",
                info: `Red squirrel's fur can have a range of colors, from grey-brown to a rushty red. They will usually have a ring
                of white around their eyes and have a white neck and belly. These squirrels can be mistaken for the gray squirrel. Main
                indeicators are pointed fur on the ears, as well as a smaller solid color tail. They are also one of the smaller species
                of squirrels. An adult squirrel are about 19cm in full length.`,
                image: redSquirrel
            }
        ],
        senses: [
            {
                title: "Vision",
                info: `Squirrels have a very distinct and good vision compared to humans, while humans have a center of focus with are 
                peripheral vision being blurry, squirrels have good detail vision in their entire line of view with a small blind spot on 
                the top of their field of view. Squirrel vision is also very heavily based on movement. They will approach a human within 
                a couple of feet if the person is standing very still, although the slightest movement can have them dashing for cover. 
                Squirrels are also sensitive to blue and red colours but cannot see red very well.`,
                image: goodEyes
            },
            {
                title: "Hearing",
                info: `A squirrels hearing is quite fair compared to humans, only being able to hear around 2-3x better than most humans. 
                That isn't much compared to most other big game animals or rabbits.`,
                image: fairHearing
            },
            {
                title: "Smell",
                info: `A squirrel's smell is quite fair and is used primarily for searching for females in heat and for deciding if a food or 
                nut is good to eat.`,
                image: fairSmell
            }
        ],
        methods: [
            {
                title: "Slow Walk",
                info: `Taking a slow, quite walk in a heavily wooded area is a good way to hunt squirrels. Usually
                you can hear them russeling in the trees or seem them jumping from brach to branch. If you find one
                squrriel it is likely there is another one in the area. Squirrels can range from 2 to 20 in a one
                acre area.`,
                note: `If you have a hard time being quite in your wooded area, use a shotgun instead of a rifle.`,
                image: woodedLand
            }, {
                title: "Sit and Wait",
                info: `If you know squirrels are in the area, just walk in the middle of it and sit under a tree. You'll
                spook everything whiel you walk in but after 20-30mins they should come back out from hidding. Once they come
                back out youn should have a decent shot at getting a squirrel.`,
                note: `Using a .22 airgun or longrifle is a choice for this method. You will have more steady shots instead and 
                have opportunities were the squirrel is staying still.`,
                image: waiting
            }, {
                title: "Baiting",
                info: `A really easy way to get squirrel is to set bait and wait. Corn, black walnuts from 
                the area, or even peanutbutter is great. The walnuts have a very strong odder that they will smell
                from their nets, and so will the peanutbutter. Using corn will only really work if they are close by
                so they can see it because corn doesn't really have a smelll. Once you set your bait, it will only take
                about 20 mins or so before a squirrel will come out, IF they are in the area.`,
                note: `A .22 airgun or longrifle is the go to here. You can set up at a further distance and be less likely to 
                be spotted by them. Also, they aren't as loud as a shotgun. In some situations, if there are more than 
                one squirrel, becasue the .22 is realitvely quite, they may stick around and not run off.`,
                image: walnuts
            }
        ]
    }

    const GrouseObj = {
        header: {
            title: "Grouse",
            info: "Grouse hunting is the more difficult by far. They are hard to get a shot, but it's possible with patience."
        },
        identification: [
            {
                sex: "Ruffed Grouse",
                info: `The Ruffed Grouse is a medium-sized game bird with a short crest. A well-camouflaged bird with intricately 
                barred and spotted plumage. Individuals can be grayish brown overall, reddish brown, or an intermediate tawny brown.
                The fur behind their head and down their neck will usually looked ruffed up as if they jsut got out of bed, hense the 
                name ruffed grouse.`,
                image: ruffedGrouse
            },
            {
                sex: "Spruce Grouse",
                info: `The Spruce Grouse is a medium-sized chicken like bird. Males having a slate grey head and neck, a red eyebrow, a 
                black chest, and white spots on the lower belly. While females come in two colour morphs, gray and chestnut.`,
                image: spruceGrouse
            },
            {
                sex: "Sharp-tailed Grouse",
                info: `The Sharp-tailed Grouse is round and chicken like with long and pointed central tail feathers and a small, slightly 
                crested head. Purple skin patches are visible on the male’s neck during courtship displays. While females are barred in brown, black.`,
                image: sharpTailGrouse
            }
        ],
        senses: [
            {
                title: "Vision",
                info: `As with most birds, the grouse's sense of sight is its most important and it doesnt take much 
                imagination to determine why, Hurtling through a maze of trees and branches at 25 miles per hour needs 
                a keen eyesight, combined with swift reflex.`,
                image: goodEyes
            },
            {
                title: "Hearing",
                info: `The structure of the grouse's cochlea, which processes sounds, is only about one-tenth the size 
                of that found in similar sized mammals, leading to a belief that their hearing is acute.`,
                image: fairHearing
            },
            {
                title: "Smell",
                info: `A sense of smell is not very important to the grouse so the corresponding organs are alot smaller.`,
                image: badSmell
            }
        ],
        methods: [
            {
                title: "Slow Walk",
                info: `Taking a slow, quite walk in a heavily wooded area is a good way to hunt Grouse.
                Grouse are usually very still animals. They will sit in a tree for hours at a time and watch.
                You'll mainly spot grouse in pine or spruce like trees. They will sit still until you get too 
                close to them. When you scare them they'll fly away to behind a tree or other cover. When they fly 
                their wings make a distinct noise that sounds like a helicoper, copping through the air. If you see
                which way the grouse went, you might be able to follow it slowly, and spot it again. They usually 
                only fly about 40 yard a time.`,
                note: `A Shotgun is a must with grouse. You will rarely get a really good chance at a slow steady shot. Usually
                once you spot and aim at them, you have about 5 to 10 seconds before they fly away`,
                image: woodedLand
            }, {
                title: "Baiting",
                info: `A really easy way to get Grouse is to set bait and wait. Corn is your best bet, but will only 
                really work well if they are close by so they can see it. Grouse don't feed for a long time. They usually
                come around in an area the same time every day. You will have about a 1 to 2 hour span of when they enter 
                the area to feed before they leave. Highly recommend using a game camera to figure out when they are in the
                area, as well as a gorund blind to hide your self well. They have a great sense of site, wihtout a blind
                you'll need to move very very slwoly without distrubing them`,
                note: `A .22 air or longrifle is recommended because you will be able to set up a steady shot before hand.`,
                image: bait
            }
        ]
    }

    const smallGameGear = [
        {
            title: "Shotgun",
            info: `A 20 gauge shotgun with a full or modified choke is recommended for most small game hunting. 
            Shot size also depends on the hunter. Usually a No.6 or 5 is good for a wide range of small game. You 
            can use 7 or 8, but this usually ruins a lot of the meat on the animal. Any pump or break action  shotguns 
            are highly recommended for many reasons. They are extremly safe, you can have a shell in the gun, but not 
            in the chamber or "choked". This will avoid miss firing because if you pull the trigger by acidient, there 
            is no shell being fired. Only downsize is that you have a pump action, you may forget to pump it when you 
            see an animal. With a break action, its obviously cracked open but the movement of you closing it may
            scare off the animal, or you might get debre in or on the shotgun or shells.`,
            image1: smallGameShotgun,
            image2: smallGameShot
        },
        {
            title: ".22 Long Rifle",
            info: `.22 long rifle is the perfect hunting rifle for all small game. They only leave one hole in the animal 
            versus a shotgun that leaves multiple. You can take much further and percise shots. Any .22 with or without a 
            scope is more than enough, you wont be taking any longer shots than around 40 yards. Using .22 rounds that are
            subsonics are great because they are much quieter than regular .22 rounds. This will help a lot with neighbours 
            that complain and as well as not spooking the entire hunting ground. Subsonice do come at a premium price, but 
            they are much better quality bullets and are less likely to jam a cheaper gun.`,
            image1: rouger22,
            image2: subsonic
        },
        {
            title: "Air Rifles and Pellet Guns",
            info: `With air rifles and pellet guns, you have two main choices. You can either get a .177 cal with a high FPS, or 
            a .22 cal with lower FPS. For a .177 cal pellet gun, you need at least 700 FPS or more to guarantee a kill. Also, with 
            .177 cal, you need to be a lot more percise on your shot placement. Heart, lungs, or head are your only options, miss 
            and you highly risk not killing and severly injuring the animals. A .22 cal pellet gun doesn't need as much FPS as a 
            .177 does. You only need 495 FPS for a guaranteed kill. With a .22 cal you can also take less percise shots without 
            the high risk of only injuring the animal. Keep in mind, the higher the caliber the less accurate the shot becomes, 
            but the more deadly the projectile becomes to these small animals.There are many differen't types of pellets for a 
            pellet/air gun. Each have thier own special quality like Super Points that are very accuate, or Hollow Points that 
            pack extra damage. Buy variety of pellets and see which you like best.  A great pellet gun is the Phantom .22 Air 
            Rifle. This will put down rabbits, grouse, and squirrels no problem.`,
            image1: pelletGun22,
            image2: pellets
        },
    ]

    return (
        <article className="small-game">
            <MetaTags>
                <title>Small Game</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Small Game like rabbits, grouse and squirrels.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <div className="bear__first-wrapper">
                <img className="bear__back" src={background} alt="background" />
            </div>
            <AnimalNavigationBar />
            <div className="small-game__limit">
                <div className="small-game__wrapper">
                    <AnimalHeader header={mainObj.header} />
                    {/* will put all gear for all small game animals right after main title, because it is basially all the same */}
                    <AnimalGear gear={smallGameGear} />
                    <HunterOrange animalOrange={mainObj.orange.info} />
                    <div>
                        <AnimalHeader header={rabbitObj.header} />
                        <AniamlIdentification identification={rabbitObj.identification} />
                        <AnimalSenses senses={rabbitObj.senses} />
                        <HuntingMethods methods={rabbitObj.methods} />
                    </div>
                    <div>
                        <AnimalHeader header={SquirrelObj.header} />
                        <AniamlIdentification identification={SquirrelObj.identification} />
                        <AnimalSenses senses={SquirrelObj.senses} />
                        <HuntingMethods methods={SquirrelObj.methods} />
                    </div>
                    <div>
                        <AnimalHeader header={GrouseObj.header} />
                        <AniamlIdentification identification={GrouseObj.identification} />
                        <AnimalSenses senses={GrouseObj.senses} />
                        <HuntingMethods methods={GrouseObj.methods} />
                    </div>
                </div>
            </div>
        </article>
    );
}

export default SmallGame;
