import React from "react";

import "./AnimalPresentation.scss";

const AnimalPresentation = (props) => {
  let { name, img, description } = props.animal;
  return (
    <div className="AnimalPresentation">
      <section className="AnimalPresentation__info">
        <h3 className="AnimalPresentation__name">{name}</h3>
        <p>{description}</p>
      </section>
      <img className="AnimalPresentation__img" src={img} alt={name} />
    </div>
  );
};

export default AnimalPresentation;
