import React from "react";
import { Link } from "react-router-dom";

import "./Header.scss";

const Header = () => {
  return (
    <header className="Header">
      <nav className="Header__nav">
        <Link className="Header__nav-link" to="/">Home</Link>
        <Link className="Header__nav-link" to="/hunting">Hunting</Link>
        <Link className="Header__nav-link" to="/fishing">Fishing</Link>
        <Link className="Header__nav-link" to="/shop">Shop</Link>
        <Link className="Header__nav-link" to="/donations">Donations</Link>
      </nav>
      <h1 className="Header__title">Ontario Wilderness</h1>
    </header>
  );
};

export default Header;
