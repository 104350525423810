//external imports
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

//other react files
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import Hunting from "./Pages/Hunting/Hunting";
import Fishing from "./Pages/Fishing/Fishing";
import Shop from "./Pages/Shop/Shop";
import DonationPage from "./Pages/DonationPage/DonationPage";
import Footer from "./Components/Footer/Footer";
import Bear from "./Pages/AnimalPages/Bear/Bear";
import Deer from "./Pages/AnimalPages/Deer/Deer";
import Moose from "./Pages/AnimalPages/Moose/Moose";
import WaterFowl from "./Pages/AnimalPages/WaterFowl/WaterFowl";
import SmallGame from "./Pages/AnimalPages/SmallGame/SmallGame";
import Turkey from "./Pages/AnimalPages/Turkey/Turkey";
import FindWMU from "./Pages/FindWMU/FindWMU";
import PublicHunting from "./Pages/PublicHunting/PublicHunting";
import ParkPage from "./Pages/ParkPage/ParkPage";
import ThankYouPage from "./Pages/ThankYouPage/ThankYouPage";
import Trout from "./Pages/FishingPages/Trout/Trout";
import Bass from "./Pages/FishingPages/Bass/Bass";
import NorthernPike from "./Pages/FishingPages/NorthernPike/NorthernPike";
import Walleye from "./Pages/FishingPages/Walleye/Walleye";
import Panfish from "./Pages/FishingPages/Panfish/Panfish";
import Mcraepoint from "./Pages/ParkPage/McRaePoint/McRaePoint";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import ads from "./Pages/AdsPage/AdsPage";

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/hunting" component={Hunting} />
                <Route exact path="/hunting/black-bear" component={Bear} />
                <Route exact path="/hunting/deer" component={Deer} />
                <Route exact path="/hunting/moose" component={Moose} />
                <Route exact path="/hunting/turkey" component={Turkey} />
                <Route exact path="/hunting/waterfowl" component={WaterFowl} />
                <Route exact path="/hunting/small-game" component={SmallGame} />
                <Route exact path="/fishing" component={Fishing} />
                <Route exact path="/fishing/bass" component={Bass} />
                <Route exact path="/fishing/panfish" component={Panfish} />
                <Route exact path="/fishing/pan-fish" component={Panfish} />
                <Route exact path="/fishing/northern-pike" component={NorthernPike} />
                <Route exact path="/fishing/trout" component={Trout} />
                <Route exact path="/fishing/walleye" component={Walleye} />
                <Route path="/Shop" component={Shop} />
                <Route path="/donations" component={DonationPage} />
                <Route path="/find-WMU" component={FindWMU} />
                <Route exact path="/public-hunting-spots" component={PublicHunting} />
                <Route path="/public-hunting-spots/McRae\ Point" component={Mcraepoint} />
                <Route path="/public-hunting-spots/:park" component={ParkPage} />
                <Route path="/thank-you/:amount" component={ThankYouPage} />
                <Route path="/404" component={NotFoundPage} />
                <Redirect to="/404" />
            </Switch>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
