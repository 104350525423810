import React from 'react';
import HuntingMethodCard from '../HuntingMethodCard/HuntingMethodCard';
import './HuntingMethodsList.scss';

const HuntingMethodsList = ({methods}) => {
    return (
        <div className="hunting-methods-list">
            {methods.map((data) => {
                return(<HuntingMethodCard 
                    key={data.title}
                    title={data.title} 
                    info={data.info} 
                    note={data.note} 
                    image={data.image} />)
            })}
        </div>
    );
}

export default HuntingMethodsList;
