import React, { useState } from 'react';
import axios from 'axios';
import './Footer.scss';

import instagramIcon from "../../Images/GeneralWebsiteArt/instagram-icon.png";
import youtubeIcon from "../../Images/GeneralWebsiteArt/youtube-icon.png";

const Footer = () => {
    const [showOverlay, setOverlay] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    function toggleOverlay() {
        setOverlay(!showOverlay);
        setEmail("");
        setMessage("");
    }

    const handleSubmit = event => {
        event.preventDefault();
        setOverlay(!showOverlay);
        setEmail("");
        setMessage("");

        axios.post("/mail/send", { email, message })
            .then((response) => {
                console.log(response);
                console.log("Email Sent!")
            })
    }

    return (
        <footer className="foot">
            <div className="foot__title-wrapper">
                <h3 className="foot__title">Canadian Wilderness</h3>
                <p className="foot__p">Powered By Joseph Abate Software</p>
            </div>
            <div>
                <button className='foot__feedback' onClick={toggleOverlay}>Leave Some Feedback Here</button>
            </div>
            <div className="foot__social">
                <div>
                    <a className="foot__anchor" href="https://www.instagram.com/ca_wilderness/">
                        <img className="foot__icon" src={instagramIcon} alt="ig icon" />
                        CA Wilderness Instagram
                    </a>
                </div>
                <div>
                    <a className="foot__anchor" href="https://www.youtube.com/channel/UC3NiMajnF9IpCKDJBSSPXZA">
                        <img className="foot__icon" src={youtubeIcon} alt="youtube icon" />
                        CA Wilderness Youtube
                    </a>
                </div>
            </div>
            <div className="foot__overlay" style={{ display: (showOverlay) ? "block" : "none" }}>
                <div className='foot__rel'>
                    <div className="overlay__backWhite"></div>
                    <div className="overlay__wrapper">
                        <h2 className="overlay__title">Leave Feedback</h2>
                        <form onSubmit={handleSubmit} className="overlay__form">
                            <label className="overlay__label" htmlFor="email">Email</label>
                            <input name="email" type="Email" className="overlay__input" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                            <textarea name="message" className="overlay__message" value={message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                            <div className="overlay__form-wrapper">
                                <button className="overlay__submit" type="submit">Submit</button>
                                <button className="overlay__submit overlay__submit--out" onClick={toggleOverlay}>exit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
