import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './FishTackle';

const FishTackle = ({info, tackleList}) => {
    return (
        <section>
            <h2>Tackle & Baits</h2>
            <p>
                {info}
            </p>
            <ul>
                {tackleList.map((index)=>{
                    return <li key={uuidv4}>{index}</li>
                })}
            </ul>
        </section>
    );
}

export default FishTackle;
