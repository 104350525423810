import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AniamlIdentification from '../../../Components/AniamlIdentification.js/AniamlIdentification';
import AnimalGear from '../../../Components/AnimalGear/AnimalGear';
import AnimalHeader from '../../../Components/AnimalHeader/AnimalHeader';
import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';
import HuntingMethods from '../../../Components/HuntingMethods/HuntingMethods';
import AnimalSenses from '../../../Components/AnimalSenses/AnimalSenses';
import AnimalBehaviour from '../../../Components/AnimalBehaviour/AnimalBehaviour';
import HunterOrange from '../../../Components/HunterOrange/HunterOrange';

import './Turkey.scss';

import male from '../../../Images/HuntingPictures/Turkey/turkeyGobbler.jpg';
import female from '../../../Images/HuntingPictures/Turkey/turkeyHen.jpg';

import goodEye from '../../../Images/HuntingPictures/senses/good/sensesEyeSightGood.png';
import fairSmell from '../../../Images/HuntingPictures/senses/fair/sensesSmellFair.png';
import goodHearing from '../../../Images/HuntingPictures/senses/good/sensesHearingGood.png';

import remington870 from '../../../Images/HuntingPictures/Gear/Remington87012G.jpg';
import shotgunShells from '../../../Images/HuntingPictures/Gear/turkeyShotandChoke.png';
import crossbow from '../../../Images/HuntingPictures/Gear/AXE360Crossbow.jpg';
import broadhead from '../../../Images/HuntingPictures/Gear/magnusBullhead.png';
import AniamlActivity from '../../../Components/AniamlActivity/AniamlActivity';

import turkeyTrailCam from '../../../Images/HuntingPictures/Turkey/turkeyTrailCam.jpg';
import roostingTurkey from '../../../Images/HuntingPictures/Turkey/roostingTurkey.jpg';
import turkeyTracks from '../../../Images/HuntingPictures/Turkey/turkeyTracks.jpg';

import roostingTheTurkey from '../../../Images/HuntingPictures/methods/RoostingTheTurkey.jpg';
import turkeyDecoys from '../../../Images/HuntingPictures/methods/turkeyDecoys.jpg';
import groundBlind from '../../../Images/HuntingPictures/methods/groundBlind.jpg';
import AnimalCalling from '../../../Components/AnimalCalling/AnimalCalling';

import turkeyCluck from '../../../Audio/Turkey/turkey_clucks_sound.mp3';
import turkeyYelp from '../../../Audio/Turkey/turkey_plain_hen_yelp_sound.mp3';
import turkeyGobble from '../../../Audio/Turkey/turkey_gobbling_sound.mp3';

import background from '../../../Images/GeneralWebsiteArt/hunting_page_background.jpg';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const Turkey = () => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const turkeyObj = {
        header: {
            title: "Wild Turkeys",
            info: "To hunt wild turkey in Ontario, you require both a small game license and a turkey tag. No special license required anymore."
        },
        identification: [
            {
                sex: "Tom",
                info: `A more brightly coloured feather body, notably larger than their 
                female counterparts. Usually weighs around 7.2~10.8kg (16-24lbs). Most 
                Males have completely featherless heads and its head can change colours 
                from red, blue and white depending on its situation. Males also have a 
                beard feather on its chest, the length of which can determine its age.
                Younger male turkeys are called Jakes and more mature males are calld
                Toms`,
                image: male
            },
            {
                sex: "Hen",
                info: "More smaller and lighter at 3.6-4.5kg(8-10lbs) and also more dull in brown and grey feathers, lacks iridescence",
                image: female
            }
        ],
        behaviour: [
            {
                title: "",
                info: `Wild turkeys are opportunistic foragers, spend most of their 
                day scratching in leaf litters, chasing bugs and milling for seeds.`
            },
            {
                title: "Turkey's Range",
                info: `Wild turkeys generally move 2-5 Kilometers a day depending on
                 habitat and food/water distance. The annual range of home for
                 turkeys varies from 370 to 1360 acres and contains a mixture of tree 
                 and grass covers.`
            },
            {
                title: "Roosting",
                info: `Turkeys use a different roosting site every night. They like 
                to choose the highest trees available and roost as high in them as 
                they can comfortably perch. In fair weather, hardwood trees are 
                favoured, while hemlocks offer good protection from the harsh 
                elements.`
            }
        ],
        senses: [
            {
                title: "Vision",
                info: `Turkeys have flattened corneas and can see colors to some 
                degree. Their  eyes are located on the side of their head, meaning 
                they have monocular, periscopic vision, meaning they have far 
                superior  peripheral vision than humans. Rotating their heads allow 
                a full 360-degree field of vision.`,
                image: goodEye
            },
            {
                title: "Hearing",
                info: `Acute hearing and lacks a flap or pinna on their ear holes, 
                meaning they can hear at a lower-frequency and more distant sounds 
                then most humans.`,
                image: goodHearing
            },
            {
                title: "Smell",
                info: `Their sense of smell is the least important to the bird. It is 
                used primarily to discern which food is best but clearly lacks compared 
                to humans.`,
                image: fairSmell
            }
        ],
        gear: [
            {
                title: "Shotgun (Must be between 10 and 20 gauge)",
                info: `A 12 gauge shotgun with an extra full choke is recommended. You need
                the extra full chock because you only want your pellets to hit the neck and
                head of the turkey. Pullets that hit the bird anywhere else will ruin the 
                meat. You can use shot sizes 4, 5, 6, or 7 for hunting turkey. Some Turkey 
                laods have a mix of  different shot sizes, just like the HEVI-SHOT Magnum  
                Blend Turkey Loads. This shotshell comes with a blend of No. 5, 6, & 7 shot
                size mixed together. A good starter shotgun that's great for many different 
                types of hunting is the Remingtion 870. This shotgun is a reliable and 
                cheap shotgun that will get the job done. Make sure whatever chock you use 
                (Full or Extra Full) it is compatible with your gun. Usually thechock packaging 
                will tell you if your model is compatible with it.`,
                image1: remington870,
                image2: shotgunShells
            },
            {
                title: "Bows & Crossbows",
                info: `Usually bows and corssbow are in sessean all year around for many differen't 
                animals, depending on the WMU you hunt in. This being said, if you plan on doing a
                 lot of hunting, a bow or corss bow is definaitly worth your money. One brand that makes out
                 standingcorss bows is Excalibur. There is a cross bow they make called the AXE 340, 
                 and this is an outstanding product. It is very light and has crazy firing power. Highly
                 recommend for all levels of turkey hunters. While shooting a crossbow, you need to
                 have at broadhead at the end of your bolt. Your broadhead legally must have 2 sharp cutting edges and
                 must be 22mm or larger. Also note that the bigger the arrow head is the easier it is to hit
                 the neck and head are on the turkey. Broad heads like the Magnus Bullhead blades are great
                 and built for turkey hunting, because the blades are in the shape of a fan that spin through the 
                 air. When these blades hit the turkey, it usually cuts the head clean off, making it a quick way 
                 to put down the bird.`,
                image1: crossbow,
                image2: broadhead
            },
        ],
        activity: [
            {
                title: "Tracks",
                info: `Turkey tracks have 3 long toes and they are about 3 to 4 inches in length. Sometimes 
                there will be a little point sticking out from the back of the track.`,
                image: turkeyTracks
            },
            {
                title: "Roosting Turkeys",
                info: `Look out for trees that seem like they are being roosted in. These are usally thick areas
                near food or water. Some times you can spot turkeys in a tree from pretty far away if there aren't
                leafs on the tree.`,
                image: roostingTurkey
            },
            {
                title: "Game Cameras",
                info: `A trail cam will also give you a pretty good idea of what Turkeys are in the area. They helps 
                you figure out what habbits they have. A trail also cam let's you create a schedule of when the birds
                come out. Moultrire® Cameras are recommended. They are reliable and provide great results.`,
                image: turkeyTrailCam
            }
        ],
        methods: [
            {
                title: "Roosting the Turkey",
                info: `Setup spots to hide in around general turkey roosting sites. 
                These sites will be high trees near either a food or water source 
                such as a river. Always keep a considerable distance from the roosting 
                site around 200-250 yards at least as to not risk scaring off the birds.`,
                note: `makeshift blinds or really good camo is essental for this type of hunting.
                While the turkey is in their roost, they have a great view of everything and have
                a really good chance of spotting you`,
                image: roostingTheTurkey
            }, {
                title: "Using Decoys",
                info: `Setup a Hen decoy and/or Jake. Make it look as if the Jake is ready to 
                breed with the Hen. Use the plain hen yelp call, as it is the best call to use 
                with these decoys because it will attract males to the hen decoy looking to bread. 
                This will usually knock the bird off guard and you can get away with more 
                movement than usual`,
                note: `Best shot to take is to wait for when the turkey is either mounting the
                decoy to bread, or if the turkey is infront of you with a good clean shot.`,
                image: turkeyDecoys
            }, {
                title: "Ground Blinds",
                info: `A good method of waiting for a bird to pass by, combination 
                using a blind and a hen decoy is usually the best option. The blind will
                let you to get away with lots of movement. If you plan on using calls that 
                require both of your hadsn to use, the cover from a blind will allow you to
                switch between the call and your bow or shotgun without much risk.`,
                note: `While in the blind, wear all black and try to keep all windows closed
                but the one you are shooting out of. Also, make sure to sit as far back as 
                you possibly can in the blind to aviod letting the light from the window to hit 
                your face. This will make you virtually invisble to anything looking inside.`,
                image: groundBlind
            }
        ],
        calling: {
            info: `Calling in turkey hunting is a major asset. If you know how to use your calls properly, 
            you are almost guaranteed a turkey if they are in the area.`,
            calls: [
                {
                    title: "Clucks",
                    info: `This is a great call while trying to encourage gobbler to come 
                    into range if he starts to hang up. It can also be used while birds are 
                    still on the roost to subtly let a gobbler know you are there. Pairing 
                    this with the Purr is a good idea as it will also reassure turkeys as 
                    they get close to your position.`,
                    sound: turkeyCluck
                },
                {
                    title: "Plain Hen Yelp",
                    info: `	This is a basic turkey hunting call. It is used by hens to call 
                    in Gobblers during mating season. If you can pull off a convincing yelp, 
                    you have a chance of being able to call in a turkey.`,
                    sound: turkeyYelp
                },
                {
                    title: "Gobbling",
                    info: `Hunters must be cautious using a gobble, especially on public land 
                    where it may attract fellow hunters to your position. It can also be a 
                    double-edged sword. A gobble may draw a dominate tom to you looking for a 
                    fight or you might drive away less dominant birds who want to avoid a beating. 
                    It is often used as a call of last resort. However, it can also be used 
                    effectively late in the evening when trying to get a tom to gobble on the roost.`,
                    sound: turkeyGobble
                }
            ]
        },
        orange: {
            info: `Turkey hunters usually don't need to wear hunter orange, but
            always double check to see if there is a gun season open for any other 
            big game animals.`,
            notRequired: true
        }
    }

    return (
        <article className="turkey">

            <MetaTags>
                <title>Wild Turkey</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Wild Turkey and how to hunt them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <div className="bear__first-wrapper">
                <img className="bear__back" src={background} alt="background" />
            </div>
            <AnimalNavigationBar />
            <div className="turkey__limit">
                <div className="turkey__wrapper">
                    <AnimalHeader header={turkeyObj.header} />
                    <AniamlIdentification identification={turkeyObj.identification} />
                    <AnimalBehaviour behaviour={turkeyObj.behaviour} />
                    <AnimalSenses senses={turkeyObj.senses} />
                    <AnimalGear gear={turkeyObj.gear} />
                    <AniamlActivity activity={turkeyObj.activity} />
                    <HuntingMethods methods={turkeyObj.methods} />
                    <AnimalCalling calling={turkeyObj.calling} />
                    <HunterOrange animalOrange={turkeyObj.orange.info} notRequired={turkeyObj.orange.notRequired} />
                </div>
            </div>
        </article>
    );
}

export default Turkey;
