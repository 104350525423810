import React from 'react';
import './AnimalActivityCard.scss';

const AnimalActivityCard = ({title, info, image}) => {
    return (
        <div className="animal-activity-card">
            <section>
                <h3 className="animal-activity-card__title">{title}</h3>
                <p className="animal-activity-card__info">{info}</p>
            </section>
            <img className="animal-activity-card__image" src={image} alt={title}/>
        </div>
    );
}

export default AnimalActivityCard;
