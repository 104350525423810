import React from 'react';
import './ProductCards.scss';

const ProductCards = ({title, image, link}) => {
    return (
        <div className="product-card">
            <a href={link} rel="noreferrer" target="_blank" className="product-card__wrapper">
                <h3 className="product-card__title" >{title}</h3>
                <img className="product-card__image" src={image} alt={title} />
            </a>
        </div>
    );
}

export default ProductCards;
