import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './Moose.scss';

import AnimalNavigationBar from '../../../Components/AnimalNavigationBar/AnimalNavigationBar';

import MetaTags from 'react-meta-tags';

const Moose = () => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="moose">

            <MetaTags>
                <title>Moose</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Moose and how to hunt them.`}
                />
            </MetaTags>

            <AnimalNavigationBar />
            <div className="moose__wrapper">
                <h1>Coming Soon...</h1>
            </div>
        </div>
    );
}

export default Moose;
