import React from 'react';
import HuntingMethodsList from '../HuntingMethodsList/HuntingMethodsList';
import './HuntingMethods.scss';

const HuntingMethods = ({methods}) => {
    return (
        <section className="hunting-methods">
            <h2 className="hunting-methods__title">Hunting Methods</h2>
            <HuntingMethodsList methods={methods}/>
        </section>
    );
}

export default HuntingMethods;
