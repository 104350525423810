import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

//hunting indexs
import bearIcon from "../../Images/HuntingPictures/index/black-bear-index.jpg";
import deerIcon from "../../Images/HuntingPictures/index/deer-buck-index.jpg";
import turkeyIcon from "../../Images/HuntingPictures/index/turkey-index.jpg";
import mooseIcon from "../../Images/HuntingPictures/index/moose-bull-index.jpg";
import smallIcon from "../../Images/HuntingPictures/index/samll-game-index.jpg";
import waterIcon from "../../Images/HuntingPictures/index/water-fowl-index.jpg";
import AnimalPresentation from "../../Components/AnimalPresentation/AnimalPresentation";

import "./Hunting.scss";
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";
import MetaTags from 'react-meta-tags';

const Hunting = () => {
  let animals = [
    {
      name: "Black Bear",
      img: bearIcon,
      description: `Black Bear hunting is very popular in Ontario. Black Bear can be found all over Ontario. 
        The population is extremely healthy especially far north where human activity is sparse, and the thick 
        forested wilderness lives, resulting in the perfect habitat for Black Bear.`
    },
    {
      name: "Deer",
      img: deerIcon,
      description: "A fantastic and iconic animal to hunt in Ontario."
    },
    {
      name: "Turkey",
      img: turkeyIcon,
      description: `Wild Turkey were non-existent in Ontario, but after its re-introduction, it
      has made a strong enough comeback and there are two hunting seasons in Ontario. Every year 9000
      turkeys are taken in spring.`
    },
    {
      name: "Moose",
      img: mooseIcon,
      description: "A Huge Beast that is famously hunted in Canada. Biggest animal in the Deer family."
    },
    {
      name: "Small Game",
      img: smallIcon,
      description: `Small fur-bearing animals are popular to hunt amongst hunters. Some are great for 
      beginner hunters, like the cotton-tail rabbit.`
    },
    {
      name: "Waterfowl",
      img: waterIcon,
      description: `A different way of hunting but an excellent activity to do with family or a couple friends.`,
    }
  ];

  return (
    <section className="hunting">

      <MetaTags>
        <title>Hunting Idnex</title>
        <meta
          name="description"
          content={`Interested in hunting in Ontairo? Checkout all the differen't animals like White Tail Deer, Black Bear, Waterfowl, and many more!.`}
        />
        <link rel="icon" href={iconImage} type="image/png" /> 
        <link rel="apple-touch-icon" href={iconImage} type="image/png" /> 
        <meta property="og:image" content={iconImage} />
      </MetaTags>

      <h1>Animals to Hunt</h1>
      <div>
        {animals.map((data) => {
          return <Link to={`/hunting/${data.name.replace(" ", "-")}`} key={uuid()}><AnimalPresentation animal={data} /> </Link>
        })}
      </div>
    </section>
  );
};

export default Hunting;
