import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './AnimalNavigationBar.scss';

import bearIcon from "../../Images/HuntingPictures/index/black-bear-index.jpg";
import deerIcon from "../../Images/HuntingPictures/index/deer-buck-index.jpg";
import turkeyIndex from "../../Images/HuntingPictures/index/turkey-index.jpg";
import mooseIcon from "../../Images/HuntingPictures/index/moose-bull-index.jpg";
import smallIcon from "../../Images/HuntingPictures/index/samll-game-index.jpg";
import waterIcon from "../../Images/HuntingPictures/index/water-fowl-index.jpg";

const AnimalNavigationBar = () => {
    const [drop, setDrop] = useState(false);

    return (
        <div className="animalNavigationBar">

            <div className="animalNavigationBar__drop-lines" onClick={() => { setDrop(!drop)}}>
                <div className="animalNavigationBar__drop-line"></div>
                <div className="animalNavigationBar__drop-line"></div>
                <div className="animalNavigationBar__drop-line"></div>
            </div>

            <div className="animalNavigationBar__wrapper" onClick={() => { setDrop(false)}}>
                <nav className="animalNavigationBar__drop-list" style={{ display: (drop) ? "flex" : "none" }}>
                    <Link to="/hunting/black-bear" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={bearIcon} alt="Black Bear" />
                        <h4 className="animalNavigationBar__title">Bear</h4>
                    </Link>
                    <Link to="/hunting/deer" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={deerIcon} alt="White Tail Deer" />
                        <h4 className="animalNavigationBar__title">Deer</h4>
                    </Link>
                    <Link to="/hunting/moose" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={mooseIcon} alt="Moose" />
                        <h4 className="animalNavigationBar__title">Moose</h4>
                    </Link>
                    <Link to="/hunting/small-game" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={smallIcon} alt="Small Game" />
                        <h4 className="animalNavigationBar__title">Small Game</h4>
                    </Link>
                    <Link to="/hunting/turkey" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={turkeyIndex} alt="Turkey" />
                        <h4 className="animalNavigationBar__title">Turkey</h4>
                    </Link>
                    <Link to="/hunting/waterfowl" className="animalNavigationBar__link">
                        <img className="animalNavigationBar__img" src={waterIcon} alt="Waterfowl" />
                        <h4 className="animalNavigationBar__title">Waterfowl</h4>
                    </Link>
                </nav>
            </div>
        </div>
    );
}


export default AnimalNavigationBar;
