import React from 'react';
import './ThankYouPage.scss';
import iconImage from "../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const ThankYouPage = ({ match }) => {
    return (
        <section className="ThankYou">

            <MetaTags>
                <title>Thank You</title>
                <meta
                    name="description"
                    content={`Thank you for your donation! <3`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>

            <div className="ThankYou__wrapper">
                <h2 className="ThankYou__title">Thank You!</h2>
                <div className="ThankYou__success">✔</div>
            </div>
            <p className="ThankYou__p">CA Wilderness thanks you for your generous donation of ${match.params.amount}.00</p>
        </section>
    );
}

export default ThankYouPage;
