import React from 'react';
import FishFind from '../../../Components/FishFind/FishFind';
import FishIdentification from '../../../Components/FishIdentification/FishIdentification';
import FishingNavigationBar from '../../../Components/FishingNavigationBar/FishingNavigationBar';
import FishIntro from '../../../Components/FishIntro/FishIntro';
import FishTackle from '../../../Components/FishTackle/FishTackle';
import './NorthernPike.scss';
import iconImage from "../../../Images/GeneralWebsiteArt/duck-mallard-fish.png";

import MetaTags from 'react-meta-tags';

const NorthernPike = () => {

    const fish = {
        intro: {
            title: `Northern Pike Fishing`,
            info: `
            Northern Pike are really fun to fish. You can find them all almost 
            every body of water in Ontario. These fish are great predators and 
            usually sit on the higher end of the food chain.`
        },
        indentifying: [
            {
                title: `Northern Pike`,
                info: `
                Nothern Pike can be between 18 to 40 inches. These fish are
                dark green with yellow spots on it's cheeks and all down it's 
                body. They also have sharp teeth that can easily cut weak fishing
                line.`
            }
        ],
        tackle: {
            info: `There are many different types of spoons, spinners, crank baits, 
            and jerk baits that can be used for targeting Northern Pike. Each one
            is used in differen't differen't enviernments and some have more 
            advantages than otherss.`,
            tackleList: ['Spoons', 'Large Spinners', 'Large Crank Baits', 'Large Jerk Baits', 'Minnows', 'Jigs', 'Large Spinner Baits']
        },
        findFish: {
            info: [
                {
                    title: "Nothern Pike",
                    info: `These fish can be found in many differen't areas. They usually like areas that are quiet with low traffic. 
                    They also like to hang out in vegetated water. Sometimes you can find a Nothern Pike sun bathing soaking up the sun.`
                }
            ]
        }
    };

    return (
        <article className="northernPike">
            <MetaTags>
                <title>Northern Pike</title>
                <meta
                    name="description"
                    content={`Check us out and learn about Nothern Pike and how to fish them. 
                    Check out how to find Northern Pike, and also what baits and tackle are great to 
                    use when targeting them.`}
                />
                <link rel="icon" href={iconImage} type="image/png" />
                <meta property="og:image" content={iconImage} />
            </MetaTags>
            <FishingNavigationBar />
            <div className="northernPike__wrapper">

                <FishIntro title={fish.intro.title} info={fish.intro.info} />

                <FishIdentification indentifying={fish.indentifying} />

                <FishTackle info={fish.tackle.info} tackleList={fish.tackle.tackleList} />

                <FishFind findFish={fish.findFish} />

            </div>
        </article>
    );
}

export default NorthernPike;
