import React from 'react';
import './AnimalCallingCards.scss'
import ReactAudioPlayer from 'react-audio-player';

const AnimalCallingCards = ({ call }) => {
    return (
        <section className="animalCallingCard">
            <h3 className="animalCallingCard__title">{call.title}</h3>
            <p className="animalCallingCard__info">{call.info}</p>
            <ReactAudioPlayer
                style={{width: "270px"}}
                src={call.sound}
                controls
            />
        </section>
    );
}

export default AnimalCallingCards;
